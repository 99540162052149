import { useState, useEffect } from 'react'
import { useLogin } from '../hooks/useLogin'

import { Container } from '@mui/system'
import Grid from '@mui/material/Unstable_Grid2'
import MainContainer from '../components/Layout/MainContainer/MainContainer'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { NavLink as RouterLink } from "react-router-dom"

import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { getAuth, sendPasswordResetEmail } from "firebase/auth"

export default function Login() {
   const styles = {
      // Not doing anything
      input: {
         "& .MuiFilledInput-root": {
            background: "#ffffff"
         }
      },
      actionBox: {
         backgroundColor: '#ffffff',
         padding: 3,
         borderRadius: '7px'
      },
      hempfrey: {
         maxWidth: '250px',
      }
   }

   const [ email, setEmail ] = useState('')
   const [ password, setPassword ] = useState('')
   const [ showPassword, setShowPassword ] = useState( false )
   const [ formError, setFormError ] = useState( null )

   const { errorCode, login, isPending } = useLogin()

   const renderErrorMessage = ( errorCode ) => {
      switch( errorCode ) {
         case 'auth/invalid-email':
            return 'Email address is invalid'
         case 'auth/user-not-found':
            return 'No users exist with that email'
         case 'auth/wrong-password':
            return 'Wrong password'
         case null:
            return ''
         default:
            return 'Something went wrong'
      }
   }

   useEffect( () => {
      setFormError( renderErrorMessage( errorCode ) )
   }, [ errorCode ] )
   
   const handleClickShowPassword = () => setShowPassword((show) => !show)

   const auth = getAuth()

   const handleSubmit = ( e ) => {
      e.preventDefault()

      if ( !email || !password ) {
         setFormError('Please enter a valid email and password.')
      } else {
         login( email, password )
      }
   }

   const handlePasswordReset = () => {
      
      if ( !email ) {
         setFormError('Please enter a valid email.')
         return false
      }
      
      sendPasswordResetEmail(auth, email)
         .then(() => {
            alert('Check your email for a link to reset your password.')
         })
         .catch(( error ) => {
            setFormError( renderErrorMessage( error.code ) )
      } )
   }

   return (
      <MainContainer fullWidth={ true }>
         <Box sx={{
            position: 'sticky',
            top: { md: '65px', xs: '56px', sm: '64px'},
            zIndex: 3,
            backgroundColor: "#ffffff",
            borderBottom: '1px solid #ccc',
         }}
         >
            <Container
               maxWidth="xl"
               sx={ { display: 'flex', pt: 2, pb: 2, justifyContent: 'center', gap: 3 } }
            >
               <Typography
                  component="p"
                  variant="h5"
               >
                  Need an Account?
               </Typography>
               <Box>
                  <Button
                     variant="contained"
                     component={ RouterLink }
                     to="/signup"
                  >
                     Join
                  </Button>
               </Box>
            </Container>
         </Box>

         <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
            <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
               <Box sx={ { padding: "48px 14px" } }>
                  <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                     <Grid sm={ 6 } sx={ { textAlign: 'center' } }>
                        <img style={ styles.hempfrey } src="https://firebasestorage.googleapis.com/v0/b/hempstokapp.appspot.com/o/hempstok%20logos%2Fhempstok-logo-circle.png?alt=media&token=a4136088-2eb7-4214-89bf-e3d8655f6ab2" alt="Hemphrey" width="100%" />
                     </Grid>
                     <Grid sm={ 6 } >
                        <Typography component="h1" variant="h4" textAlign="center" mb={ 3 }>Welcome Back!</Typography>
                        <Box sx={ styles.actionBox }>
                           <form onSubmit={ handleSubmit }>
                              <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                 <InputLabel htmlFor="email">Email address</InputLabel>
                                 <FilledInput
                                    id="email"
                                    onChange={ ( e ) => setEmail( e.target.value )}
                                    value={ email }
                                    autoComplete="email"
                                 />
                              </FormControl>

                              <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                 <InputLabel htmlFor="password">Password</InputLabel>
                                 <FilledInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={ ( e ) => setPassword( e.target.value )}
                                    value={ password }
                                    sx={ styles.input }
                                    autoComplete="current-password"
                                    endAdornment={
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={ handleClickShowPassword }
                                             edge="end"
                                          >
                                             { showPassword ? <VisibilityOff /> : <Visibility /> }
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                 />
                              </FormControl>                              
                              
                              <Box mt={ 3 } sx={ { display: 'flex', justifyContent: 'space-between' } }>
                                 <Button
                                    variant='text'
                                    onClick={ handlePasswordReset }
                                 >
                                    Reset Password
                                 </Button>
                                 
                                 {
                                    ( isPending )
                                    ?
                                       (
                                          <Button variant="contained" size="medium" disabled>
                                             Logging In
                                          </Button>
                                       )
                                    :
                                       (
                                          <Button variant="contained" size="medium" type="submit">
                                             Log In!
                                          </Button>
                                       )
                                 }
                              </Box>
                              { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                           </form>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>      
      </MainContainer>
   )
}
