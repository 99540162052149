import { useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'

import { auth } from '../firebase/config'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useFirestore } from './useFirestore'

export const useSignup = () => {
   const [ isCancelled, setIsCancelled ] = useState( false )
   const [ errorMessage, setErrorMessage ] = useState( null )
   const [ errorCode, setErrorCode ] = useState( null )
   const [ isPending, setIsPending ] = useState( false )
   const { dispatch } = useAuthContext()

   const { addDocument, response } = useFirestore('users')

   const signup = async ( email, password, name, inviteCode = '' ) => {
      setErrorMessage( null )
      setErrorCode( null )
      setIsPending( true )

      try {
         const res = await createUserWithEmailAndPassword( auth, email, password )
         
         if ( !res ) {
            throw new Error('Could not complete signup')
         }
         
         addDocument( {
            name,
            email,
            favs: [],
            inviteCode,
         }, res.user.uid )


         dispatch({ type: 'LOGIN', payload: res.user })
         
         if ( !isCancelled ) {
            setIsPending( false )
            setErrorMessage( null )
            setErrorCode( null )
         }

      } catch( err ) {
         if ( !isCancelled ) {
            setErrorMessage( err.message )
            setErrorCode( err.code )
            setIsPending( false )
         }
      }
   }
      
   // I think the return is execuited when the componenet unmounts?
   useEffect( () => {
      return () => setIsCancelled( true )
   }, [])

   return { errorMessage, errorCode, signup, isPending }
}

/*
import { useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'

// firebase imports
import { auth, storage } from '../firebase/config'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useFirestore } from './useFirestore'

// import { v4 } from 'uuid'

export const useSignup = () => {
  const [ isCancelled, setIsCancelled ] = useState( false )
  const [ error, setError ] = useState( null )
  const [ isPending, setIsPending ] = useState( false )
  
  const { dispatch } = useAuthContext()
  const { addDocument, response } = useFirestore('users')

  const signup = async ( email, password, displayName, username, thumbnail ) => {
    setError( null )
    setIsPending( true )

    try {
      // signup
      const res = await createUserWithEmailAndPassword( auth, email, password )

      if ( !res ) {
        throw new Error('Could not complete signup')
      }

      // upload user thumbnail
      const uploadPath = `thumbnails/${ res.user.uid }/${ thumbnail.name }`
      const thumbnailRef = ref( storage, uploadPath )
      const img = await uploadBytes( thumbnailRef, thumbnail )
      const imgUrl = await getDownloadURL( img.ref )

      // add display AND PHOTO_URL name to user
      // await updateProfile( res.user, { displayName, photoURL: imgUrl } )

      // Create entry into users
      addDocument( {
        displayName,
        photoURL: imgUrl,
        email,
        username
      }, res.user.uid )

      dispatch( { type: 'LOGIN', payload: res.user } )

      if ( !isCancelled ) {
        setIsPending( false )
        setError( null )
      }
    } catch( err ) {
      if ( !isCancelled ) {
        setError( err.message )
        setIsPending( false )
      }
    }
  }

  // I think the return is execuited when the componenet unmounts?
  useEffect(() => {
    return () => setIsCancelled( true )
  }, [])

  return { error, signup, isPending }
}

*/
