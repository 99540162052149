import { useState, useEffect } from "react"

import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase/config'

import Button from '@mui/material/Button'

import {
   DataGrid,
   GridActionsCellItem,
   GridToolbarContainer,
   GridToolbarFilterButton
} from '@mui/x-data-grid'

import EditIcon from '@mui/icons-material/Edit'
import { useCollection } from "../../hooks/useCollection"
import MainContainer from "../../components/Layout/MainContainer/MainContainer"
import ProductModal from "./ProductModal"

export default function Dashboard() {
   const [ productModalOpen, setProductModalOpen ] = useState( false )
   const [ productId, setProductId ] = useState( null )
   const [ vendor, setVendor ] = useState( null )  
   const [ products, setProducts ] = useState( [] )
   const [ dropQueue, setDropQueue ] = useState( false )

   const handleProductModalOpen = ( productId, slug ) => {
      setProductModalOpen( true )
      setProductId( productId )
      setVendor( slug )
   };
   
    const handleProductModalClose = () => {
      setProductModalOpen( false );
   };
   
   function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      );
   }

   // get all the vendors
   const { documents: vendors } = useCollection( 'vendors' )

   useEffect( () => {
      if ( vendors ) {
         let ref = null
         vendors.forEach( vendor => {

            /*
            // Get data once
            const querySnapshot = await getDocs( collection( db, `vendors/${ vendor.slug }/products` ) )
            querySnapshot.forEach( ( doc ) => {
               setProducts( oldArray => [ ...oldArray, { ...doc.data(), id: doc.id, slug: vendor.slug } ])
            })
            */
            
            ref = collection( db, `vendors/${ vendor.slug }/products` )

            // Realtime data
            const unsubscribe = onSnapshot( ref, snapshot => {
               snapshot.forEach( doc => {
                  setProducts( oldArray => {
                     return [
                        ...oldArray.filter( el => el.id !== doc.id ),
                        { ...doc.data(), id: doc.id, slug: vendor.slug }
                     ]
                  } )
               } )
            } )

            // Clean up function for when component unmounts
            return () => unsubscribe()
         } )

      } 
   }, [ vendors ] )

   let productsFiltered = products

   if ( dropQueue ) {
      productsFiltered = products.filter(
         prod => ( prod.queue === 'new' || prod.queue === 'restock' )
      )
   }

   const getDateValue = ( date ) => {
      if ( !date ) {
         return '';
      }
      return date.toDate()
   }
   
   const oneDay = new Date().getTime() - (1 * 24 * 60 * 60 * 1000)
   
   const columns = [
      // edit icon
      {
         field: 'actions',
         headerName: 'Actions',
         type: 'actions',
         getActions: ( params ) => [
            <GridActionsCellItem
               icon={ <EditIcon /> }
               label="Edit"
               //onClick = { params.id => handleProductModalOpen( id ) }
               onClick = { () => handleProductModalOpen( params.id, params.row.slug ) }
            />
         ]
      },

      // ID (hidden)
      {
         field: 'id',
         headerName: 'ID',
         width: 70,
      },
   
      // In stock
      {
         field: 'in_stock',
         headerName: 'In Stock',
         width: 100,
         type: 'boolean'
      },
   
      // Thumbnail
      {
         field: 'thumb_src',
         headerName: '',
         width: 100,
         renderCell: ( params ) => 
            <img src={ params.row.thumb_src } alt={ params.row.title } width="50px" />
      },
   
      // Title/name
      {
         field: 'title',
         headerName: 'Title',
         width: 200,
         renderCell: ( params ) => 
            <a href={ params.row.profile_url } target="_blank">{ params.row.title }</a>
      },
   
      { field: 'slug', headerName: 'Vendor', width: 130 },
   
      
      // New Date
      {
         field: 'new_date',
         headerName: 'New',
         width: 175,
         type: 'dateTime',
         valueGetter: (params) => getDateValue( params.row.new_date ),
         renderCell: ( params ) => {
            if ( params.value.getTime() > oneDay ) {
               return (
                  <Box sx={ { color: 'green' } }>
                     { params.formattedValue }
                  </Box>
               )
            }
            
            return params.formattedValue
         }
      },
   
      // Restock Date
      {
         field: 'restock_date',
         headerName: 'Restocked',
         width: 175,
         type: 'dateTime',
         valueGetter: (params) => getDateValue( params.row.restock_date ),
         renderCell: ( params ) => {
            if ( ! params.value ) {
               return ""
            }
            
            if ( params.value.getTime() > oneDay ) {
               return (
                  <Box sx={ { color: 'green' } }>
                     { params.formattedValue }
                  </Box>
               )
            }
            
            return params.formattedValue
         }
      },
      
      // Updated Date
      {
         field: 'update_date',
         headerName: 'Updated',
         width: 175,
         type: 'dateTime',
         valueGetter: (params) => getDateValue( params.row.update_date ),
      },
      
      // Out of Stock Date
      {
         field: 'destock_date',
         headerName: 'Destocked',
         width: 175,
         type: 'dateTime',
         valueGetter: (params) => getDateValue( params.row.destock_date ),
      },
   
      { field: 'hemp_type', headerName: 'Type', width: 75 },
      { field: 'on_sale', headerName: 'On Sale', width: 100, type: 'boolean' },
      { field: 'queue', headerName: 'Queue', width: 100, type: 'text' },
   ]
   
   
   return (
      <>
         <MainContainer fullWidth>
            <Container maxWidth="xl">
               {/* <CreateProduct /> */}

               <Box mt={ 2 } mb={ 2 }>
                  <Button
                     variant={ dropQueue ? 'contained' : 'outlined' }
                     onClick={ () => setDropQueue( ! dropQueue ) }
                  >
                     Drop Queue
                  </Button>
               </Box>
               <Box height="1000px" mt={ 2 }>
                  { productsFiltered && (
                     <>
                        <DataGrid
                           rows={ productsFiltered }
                           columns={ columns }
                           initialState={{
                              pagination: {
                                 paginationModel: { page: 0, pageSize: 100 },
                              },
                              sorting: {
                                 sortModel: [{ field: 'new_date', sort: 'desc' }],
                              },
                           }}
                           pageSizeOptions={ [ 100 ] }
                           checkboxSelection
                           slots={{ toolbar: CustomToolbar }}
                           columnVisibilityModel={ {
                              id: false,
                           } }
                        />
                     </>
                  ) }
               </Box>
            </Container>        
         </MainContainer>
         { productId && vendor && <ProductModal
               open = { productModalOpen }
               productId = { productId }
               vendor = { vendor }
               handleModalClose = { handleProductModalClose }
            />
         }
      </>
   )
}
