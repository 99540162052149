import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

// define your extension array
const extensions = [
   StarterKit,
]

export default function TextEditor( { content, setDescription } ) {
   const editor = useEditor( {
      extensions,
      content,
      onUpdate( { editor } ) {
         setDescription( editor.getHTML() )
      },
   }, [ content ] )

   return (
      <EditorContent editor={ editor } />
   )
}