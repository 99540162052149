import { Helmet } from 'react-helmet'
import { useAuthContext } from '../../hooks/useAuthContext'

export default function MailingListSignUp() {
   const { user } = useAuthContext()

   let mailingList = ''

   if ( !user ) {
      mailingList = (
         <Helmet>
            <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/43908209.js"></script>
         </Helmet>
      )
   }
   
   return (
      mailingList
   )
}
