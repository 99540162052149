import { useState, useEffect, memo } from "react"

import { collection, onSnapshot, query, where, getDocs, orderBy, Timestamp, or } from 'firebase/firestore'

import { useTheme } from '@mui/material/styles'

import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { Helmet } from "react-helmet"

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

import { db } from '../firebase/config'

import { Link, NavLink as RouterLink } from "react-router-dom"

import MainContainer from '../components/Layout/MainContainer/MainContainer'
import ListCard from '../components/ListCard/ListCard'

import { useCollection } from "../hooks/useCollection"

import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime';

import Scroller from "../components/Layout/Scroller/Scroller"

// import { useTheme } from "@mui/material/styles"

const Item = styled( Paper )( ( { theme } ) => ( {
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   // padding: theme.spacing(1),
   color: theme.palette.text.secondary,
} ) )

const today = new Date()
const oneWeek = Timestamp.fromDate( new Date( today.setDate(today.getDate() - 7 ) ) )

export default function Drops() {
   const [ products, setProducts ] = useState( [] )

   dayjs.extend( relativeTime )

   const theme = useTheme()

   const styles = {
      timeline: {
         borderBottom: '1px solid #999',
         position: 'relative',
         marginTop: '3rem',
      },
      timeSinceUpdate: {
         backgroundColor: '#999',
         display: 'inline-block',
         position: 'absolute',
         bottom: '-.9em',
         left: '1em',
         fontSize: '11px',
         letterSpacing: '.5px',
         color: '#fff',
         padding: '3px 6px',
         borderRadius: '7px',
         fontWeight: 'bold'
      }
   }

   // get all the vendors
   const { documents: vendors } = useCollection( 'vendors' )

   useEffect( () => {
      if ( vendors ) {
         let q = null
         vendors.forEach( vendor => {

            /*
            // Get data once
            const querySnapshot = await getDocs( collection( db, `vendors/${ vendor.slug }/products` ) )
            querySnapshot.forEach( ( doc ) => {
               setProducts( oldArray => [ ...oldArray, { ...doc.data(), id: doc.id, slug: vendor.slug } ])
            })
            */

            q = query(
               collection( db, `vendors/${ vendor.slug }/products` ),
               where( 'queue_date', '>', oneWeek ),
               where( 'queue', '==', 'drop' )
            )

            // Realtime data
            const unsubscribe = onSnapshot( q, snapshot => {
               snapshot.forEach( doc => {
                  setProducts( oldArray => {
                     return [
                        ...oldArray.filter( el => el.id !== doc.id ),
                        {
                           ...doc.data(),
                           id: doc.id,
                           vendorName: vendor.name,
                           vendorSlug: vendor.slug,
                        }
                     ]
                  } )
               } )
            } )

            // Clean up function for when component unmounts
            return () => unsubscribe()
         } )

         /*
         // Sort prodcuts by update_date
         const newProducts = [ ...products ]
         newProducts.sort( ( x, y ) =>  y.update_date.seconds - x.update_date.seconds )
         setProducts( newProducts )
         */
      } 
   }, [ vendors ] )

   const productSort = ( prods ) => {
      const newProds = [ ...prods ]
      newProds.sort( ( x, y ) =>  y.queue_date.seconds - x.queue_date.seconds )
      
      return newProds
   }

   let lastTime = null
   let timeSinceLabel = ''

   return (
      <>
         <Helmet>
            <title>CBD Hemp Flower Drops</title>
            <meta  name="description" content="Browse new releases and restocks of CBD Hemp Flower Strains for Sale" />
         </Helmet>
         <MainContainer fullWidth={ true }>
            <Box sx={{
               position: 'sticky',
               top: { md: '65px', xs: '56px', sm: '64px'},
               zIndex: 3,
               backgroundColor: "#ffffff",
               borderBottom: '1px solid #ccc',
            }}
            >
               <Container
                  maxWidth="xl"
                  sx={ { display: 'flex', pt: 2, pb: 2, justifyContent: 'center', gap: 3 } }
               >
                  <Typography
                     component="p"
                     variant="h5"
                  >
                     Let's Be Friends!
                  </Typography>
                  <Box>
                     <Button
                        variant="contained"
                        component={ RouterLink }
                        to="/signup"
                     >
                        Join Hempstok
                     </Button>
                  </Box>
               </Container>
            </Box>

            <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: 10 } }>
               <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd', paddingTop: 1 } }>
                  <div className="test">Blah</div>
                  { products && <Scroller items={ products }/> }
                  { products && productSort( products ).map( product => {
                     /* 
                     // Tab
                     let tabColor = ''
                     let tabText = ''

                     // Find the latest entry
                     const entries = {
                        'new': product.new_date ? product.new_date.seconds : 0,
                        'restock': product.restock_date ? product.restock_date.seconds : 0,
                        'sale': product.sale_date ? product.sale_date.seconds : 0
                     }

                     const dropType = Object.keys( entries ).reduce( ( a, b ) => entries[ a ] > entries[ b ] ? a : b )

                     switch ( dropType ) {
                        case 'new':
                           tabColor = 'tabBlue'
                           tabText = 'NEW'
                           break
                        case 'restock':
                           tabColor = 'tabOrange'
                           tabText = 'RESTOK'
                           break
                        case 'sale':
                           tabColor = 'tabGreen'
                           tabText = 'SALE'
                           break
                        default:
                     }
                     */
                     
                     // Get timezone from user object. Might be in the auth object from firebase
                     const updateDate = product.queue_date.toDate().toLocaleString('en', {
                        timeZone: 'America/New_York'
                     })

                     const timeSinceLabel = dayjs( updateDate ).fromNow()

                     let newLabel = ''
                     
                     if ( lastTime !== timeSinceLabel ) {
                        lastTime = timeSinceLabel
                        newLabel = true
                     }

                     return (
                        <Box key={ product.id }>
                           { newLabel && (
                              <Box sx={ styles.timeline }>
                                 <Box sx={ styles.timeSinceUpdate }>
                                    { timeSinceLabel }
                                 </Box>
                              </Box>
                           ) }
                           <ListCard
                              key={ product.id }
                              product={ product }
                           />
                        </Box>
                     )
                  } ) }
               </Container>
            </Box>
         </MainContainer>
      </>
   )
}
