import { Route, Routes } from 'react-router-dom'
import { Helmet } from "react-helmet"
import VendorInventory from '../../components/VendorInventory/VendorInventory'
import FarmProfile from './FarmProfile'
import { useParams } from 'react-router-dom'

export default function CBDFlower() {

   const { slug } = useParams()

   // console.log('routeParams', slug)

   return (
      <>
         <Routes>
            { /* View by Farm */ }
            <Route path="farms" element={
               <>   
               <Helmet>
                  <meta charSet="utf-8" />
                  <meta name="description" content="Browse in-stock inventory of the best CBD farms across the nation in one platform." />
                  <title>CBD Flower Farms</title>
                  <link rel="canonical" href="https://hempstok.com" />
               </Helmet>
               
               <VendorInventory mode="farms" />
               </>
            } />

            { /* View by Strains */ }
            <Route path="strains" element={
               <>   
               <Helmet>
                  <meta charSet="utf-8" />
                  <meta name="description" content="Shop High-CBD Flower Strains, Delivered Farm-Direct to Your Door" />
                  <title>CBD Flower for Sale</title>
                  <link rel="canonical" href="https://hempstok.com" />
               </Helmet>
               
               <VendorInventory mode="strains" />
               </>
            } />

            <Route path="farms/:slug" element={
               <FarmProfile />
            } />
         </Routes>
      </>
   )
}
