import { useEffect, useState } from 'react'
import { db } from '../firebase/config'
import { doc, get, getDoc, onSnapshot } from 'firebase/firestore'; 

export const useDocument = ( collection, id ) => {

  const [ document, setDocument ] = useState( null )
  const [ error, setError ] = useState (null )

  // realtime document data
  useEffect(() => {
    const docRef = doc( db, collection, id )
    getDoc( docRef )

    onSnapshot( docRef, ( doc ) => {
      // console.log( doc.data(), doc.id )
    } )

    const unsubscribe = onSnapshot( docRef, ( snapshot ) => {
      // need to make sure the doc exists & has data
      if ( snapshot.data() ) {
        setDocument( { ...snapshot.data(), id: snapshot.id } )
        setError( null )
      }
      else {
        setError( 'No such document exists' )
      }
    }, err => {
      console.log( err.message )
      setError( 'failed to get document' )
    } )

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [ collection, id ] )

  return { document, error }
}