import VendorInventory from "../../components/VendorInventory/VendorInventory"
import { Helmet } from "react-helmet"
import MailingListSignUp from "../../components/MailingListSignUp/MailingListSignUp"
import Page from "../../components/Layout/Page/Page"

export default function Shabazz() {
   localStorage.removeItem('channel')
   localStorage.setItem("channel", "shabazzstok")

   return (
      <Page>
         <Helmet>
            <meta charSet="utf-8" />
            <title>Shabazz on Hempstok</title>
            <link rel="canonical" href="https://hempstok.com/shabazz" />
         </Helmet>
         <VendorInventory mode="shabazz" />

         <MailingListSignUp />
      </Page>
   )
}
