import { Typography } from "@mui/material"

export default function HempStats( { product } ) {
   const renderHempStat = ( label, val ) => {
      if ( !val ) return false

      let row

      if (label === 'COA') {

         row = []
         
         const arr = Object.entries( val )

         if ( !arr.length ) return false
         
         let key, value

         for ( [ key, value ] of arr ) {
            row.push(
               <Typography key={ key }>
                  <a style={ { color: '#7baa45' } } href={ value } rel="noreferrer" target="_blank" >{ key }</a>
               </Typography>
            )
         }

      } else {
         row = <Typography fontWeight={ 600 }>{ val }</Typography>
      }
      
      return (
         <tr>
            <td style={ { textAlign: 'right', verticalAlign: 'top' } }>{ label }:</td>
            <td style={ { paddingLeft: '1rem' } }>
               { row }
            </td>
         </tr>
      )
   }

   return (
      <table cellSpacing="7" style={ { marginRight: 'auto', marginLeft: 'auto' } }>
         <tbody>
            { renderHempStat( 'Type', product.hemp_type ) }
            { renderHempStat( 'Grown', product.enviro ) }
            { renderHempStat( 'Effect', product.effect ) }
            { renderHempStat( 'COA', product.coa_url ) }
            { renderHempStat( 'Reviews', product.shabazzreview ? <a href={ product.shabazzreview } rel="noreferrer" target="_blank">Shabazz</a> : null ) }
         </tbody>
      </table>
   )
}
