import { useState, useEffect, useRef } from 'react'
import { db } from '../firebase/config'

import { collection, onSnapshot, query, where } from 'firebase/firestore'

export const useCollection = ( c, _q ) => {
   const [ documents, setDocuments ] = useState(null)

   // Allows for multiple queries but stupid formatting on front end
   // [['pid', '==', '1234'], ['uid', '==', '5678']]
   const queries = []

   if ( _q ) {
      _q.forEach( ( q ) => {
         queries.push( where( ...q ) )
      } )
   }

   // Not sure if I need to make this a ref.

   // Doesn't seem to be updating per product.
   // const q = useRef( ...queries ).current
   //const q = useRef( ...queries ).current

   useEffect( () => {
      if ( !process.env.NODE_ENV || process.env.NODE_ENV === "development" ) {
         console.log('db activity', c)
      }
      
      let ref = collection( db, c )

      if ( queries ) {
         ref = query( ref, ...queries ) 
      }

      // Realtime data
      const unsubscribe = onSnapshot( ref, ( snapshot ) => {
         let results = []
         snapshot.docs.forEach( doc => {
            results.push( {...doc.data(), id: doc.id } )
         } )
         setDocuments( results )
      } )

      // Clean up function for when component unmounts
      return () => unsubscribe()
   }, [ c ]) // _p is giving me infiate state loop

   return { documents }
}