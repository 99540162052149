import { Box, Typography } from '@mui/material'

export default function ScrollerGeneral( {
   // handelClick,
   items,
} ) {

   const styles = {
      scrollerWrapper: {
         display: 'grid',
      
         // Setting up main structure
         gridTemplateColumns: '14px 1fr 14px',
      
         /* Any direct children of .scroller-wrapper will be ‘containerized’ with a 20px gap
         on both ends keeping the content off the edges. It's kinda like centering a container div.
         I need to use these selectors more
         */

         // I would like to have the productCard extend to the edge of the scroller. Currrently just scrolls inside/behind.
         // Worked on it for a bit but couldn't figure it out. Kicked my ass. 7/3/24

         '& > *': {
            gridColumn: '2 / -2',
         },
         '& > .full': {
            gridColumn: '1 / -1',
         },
         background: '#e3e4dd'
      },
      scroller: {
         display: 'grid',
         gridGap: '21px',
      
         gridAutoFlow: 'column',
      
         overflowX: 'scroll',

         /*
         '&::before': {
            content: "''",
            width: '10px',
         },
         */
      },
      noScrollbar: {
         scrollbarWidth: 'none',
         marginBottom: 0,
         paddingBottom: 0,
         '::-webkit-scrollbar': {
            display: 'none'
         }
      },
   }

   const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

   let numRowProds = 0

   switch ( true ) {
      case windowWidth < 350:
         numRowProds = 2
      break;
      case windowWidth < 600:
         numRowProds = 2
      break;
      case windowWidth < 900:
         numRowProds = 5
      break;
      case windowWidth < 1200:
         numRowProds = 6
      break;
      case windowWidth < 1300:
         numRowProds = 7
      break;
      default:
         numRowProds = 7
   }

   let cardWidth = '150'

   const fullCardWidth = ( Math.min( windowWidth, 1488 ) / numRowProds ) - ( 28 )
   cardWidth = fullCardWidth + ( ( fullCardWidth ) / numRowProds )

   // If productCardArray is empty, return a no matching products message
   if ( items.length === 0 ) {
      items.push(
         <Box sx={ { gridColumn: '1 / -1' } } key="noMatches">
            <Typography variant="h5" component="h1" sx={ { textAlign: 'center' } }>
               No matching items
            </Typography>
         </Box>
      )
   }

   return (
      <>
      <Box sx={ [ styles.scrollerWrapper ] } className="scrollerWrapper">
         <Box component="div" sx={
            [ styles.scroller, styles.noScrollbar, { gridAutoColumns: `${ cardWidth }px` } ]
         } >
            { items }
         </Box>
      </Box>
      </>
   )
}
