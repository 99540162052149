import { useContext } from "react"
import { PageContext } from "../context/PageContext"


export const usePageContext = () => {
  const context = useContext( PageContext )

  if( !context ) {
    throw Error( 'usePageContext must be used inside an PageContextProvider' )
  }

  return context
}