import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import ShabazzLogo from "./shabazz-white-300.png"
import { useTheme } from '@mui/material/styles'
import { LineWeightOutlined } from "@mui/icons-material"
import Grid from '@mui/material/Unstable_Grid2'

import { usePageContext } from "../../hooks/usePageContext"

import { useState, forwardRef, useEffect } from 'react'

const ShabazzToolbar = forwardRef( ( { mode, appBarRef, scrollToSection }, ref ) => {
   const { scrollMarginTop, toolbarHeight } = usePageContext()

   const {
      channelHeaderRef,
      couponSectionRef,
      sealSectionRef,
      dropSectionRef,
      storeSectionRef,
   } = ref

   // const activeTab = useRef( 0 )
   const [ activeTab, setActiveTab ] = useState( 0 )
   // const [ isObserving, setIsObserving ] = useState( false )

   const theme = useTheme()

   const styles = {
      root: {
         top: `${ toolbarHeight }px`,
         position: 'sticky',
         height: `${ toolbarHeight }px`,
         zIndex: 3,
         background: 'linear-gradient(180deg, rgba(255,255,255,.2) 0%, rgba(0,0,0,0) 100%)',
         backgroundColor: '#000000',
      },
      logo: {
         maxHeight: '50px',
         [ theme.breakpoints.down( 'sm' ) ]: {
            width: '100px',
         }
      },
      title: {
         flexGrow: 1,
      },
      tabButtons: {
         color: '#ffffff',
         fontWeight: 'bold',
         minWidth: '72px',
         height: '32px',
         padding: '6px 12px',
         fontSize: '0.875rem',
         textTransform: 'none'
      },
      // Holy Fuck. Just to turn it white
      whiteSelect: {
         '& .MuiSvgIcon-root': {
            color: '#ffffff',
         },
         '&.MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#ffffff', // outline-color by default
            },
            '&:hover fieldset': {
            borderColor: 'primary', // outline-color on hover
            },
            '&.Mui-focused fieldset': {
            borderColor: 'primary', // outline-color on focus
            },
         },
         color: '#ffffff',
         minWidth: '150px',
         borderColor: 'white.main',
         marginRight: '16px'
      }
   }

   const appBar = appBarRef.current
   const channelHeader = channelHeaderRef.current
   const couponSection = couponSectionRef.current
   const dropSection = dropSectionRef.current
   const sealSection = sealSectionRef.current
   const storeSection = storeSectionRef.current

   let direction = 'up'
   let prevYPosition = 0

   const setScrollDirection = () => {
      if ( channelHeader.scrollTop > prevYPosition) {
        direction = 'down'
      } else {
        direction = 'up'
      }
    
      prevYPosition = channelHeader.scrollTop
   }

   const shouldUpdate = ( entry ) => {
      // They are interseting at top of screen
      if ( entry.target.id === 'channelHeader' ) {
         return true
      }
      
      if ( direction === 'down' && !entry.isIntersecting ) {
         return true
      }
      
      if (direction === 'up' && entry.isIntersecting) {
         return true
      }
      
      return false
   }

   const onIntersect = ( entries ) => {
      entries.forEach( entry => {
         
         setScrollDirection()

         // Don't update if the entry is not intersecting
         if ( ! shouldUpdate( entry ) ) return

         switch ( entry.target.id ) {
            case 'channelHeader':
               setActiveTab( 0 )
               break
            case 'seal':
               setActiveTab( 1 )
               break
            case 'couponSection':
               setActiveTab( 2 )
               break;
            case 'drops':
               setActiveTab( 3 )
               break;
            case 'storeSection':
               setActiveTab( 4 )
               break;
            default:
               setActiveTab( 0 )
               break;
         }
      } )
   }

   useEffect( () => {
      if ( appBar ) {

         //console.log('observe')

         const observerOptions = {
            // root: document.querySelector( '#main' ),
            threshold: 0,
            
            // Negative margins ensure we only get one section at a time and it doesn't
            // trigger when element comes into the bottom.
            //rootMargin: `${ appBar.offsetHeight * -2 }px 0px -100% 0px` // sweet code!
            
            // I'm not sure if I should set the height of the app bar with react or
            // use css. What's best practice?
            // -1 so that it intersects
            rootMargin: `${ -scrollMarginTop -1 }px 0px -100% 0px`
            //rootMargin: '-130px 0px -100% 0px'
         }
      
         const observer = new IntersectionObserver( onIntersect, observerOptions )

         if ( channelHeader ) observer.observe( channelHeader )
         if ( couponSection ) observer.observe( couponSection )
         if ( dropSection ) observer.observe( dropSection )
         if ( sealSection ) observer.observe( sealSection )
         if ( storeSection ) observer.observe( storeSection )

         return () => {
            if ( channelHeader ) observer.unobserve( channelHeader )
            if ( couponSection ) observer.unobserve( couponSection )
            if ( dropSection ) observer.unobserve( dropSection )
            if ( sealSection ) observer.unobserve( sealSection )
            if ( storeSection ) observer.unobserve( storeSection )
            // setIsObserving( false )
         }
      }
   }, [ appBar ] )
   
   const [ jumpTo, setJumpTo ] = useState('');

   const handleJumpToChange = ( event ) => {
      setJumpTo( event.target.value )

      scrollToSection( ref[ event.target.value ].current )

      //ref[ event.target.value ].current.scrollIntoView( { behavior: 'instant' } )
     //console.log( 'Jump to:', event.target.value )
   };

   return (
      <Box sx={ styles.root }>
         <Container
            maxWidth="xl"
            sx={ {
               height: '100%',
               display: 'flex',
               alignItems: 'center',
               //paddingRight: { xs: 0, sm: 'inherit' }, // For the tab scroll button
            } }>
            <Toolbar sx={ { width: '100%' } } disableGutters>
               <Grid container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={ { width: '100%' } }
                  flexWrap="nowrap"
               >
                  <Grid
                  container
                  alignItems="center"
                  sx={ { lineHeight: 1, minWidth: '100px' }
               } >
                     <Link
                        to="/shabazz"
                        sx={ { margin: 0 } }
                     >
                        <Box
                           component="img"
                           src={ ShabazzLogo }
                           sx={ styles.logo }
                        />
                     </Link>
                  </Grid>
                  
                  { mode === 'shabazz' && (
                     <Grid>
                        {/* Show tabs for non-mobile devices */}
                        <Tabs
                           value={ activeTab }
                           //onChange={ handleChange }
                           textColor="primary"
                           indicatorColor="primary"
                           variant="scrollable"
                           scrollButtons="auto"
                           allowScrollButtonsMobile
                           aria-label="Shabazz Channel Menu"
                           sx={ {
                              color: '#ffffff',
                              fontWeight: 'bold',
                              display: { xs: 'none', sm: 'flex' },
                           } }
                           size="small"
                        >
                           <Tab
                              label="Top"
                              sx={ styles.tabButtons }
                              onClick={ () => {
                                 channelHeader.scrollIntoView({ behavior: 'smooth' })
                              } }
                           />
                           <Tab
                              label="Seal of Approval"
                              sx={ styles.tabButtons }
                              onClick={ () => {
                                 sealSection.scrollIntoView({ behavior: 'smooth' })
                              } }
                           />
                           <Tab
                              label="Coupons & Links"
                              sx={ styles.tabButtons }
                              onClick={ () => {
                                 couponSection.scrollIntoView({ behavior: 'smooth' })
                              } }
                           />
                           <Tab
                              label="Drops"
                              sx={ styles.tabButtons }
                              onClick={ () => {
                                 dropSection.scrollIntoView({ behavior: 'smooth' })
                              } }
                           />
                           <Tab
                              label="Shop"
                              sx={ styles.tabButtons }
                              onClick={ () => {
                                 storeSection.scrollIntoView({ behavior: 'smooth' })
                              } }
                           />
                        </Tabs>
                        <FormControl fullWidth sx={  { display: { xs: 'flex', sm: 'none' } } }>
                           <InputLabel sx={ { color: '#ffffff !important' } } id="jump-to-menu-label">Jump To:</InputLabel>
                           <Select
                              labelId="jump-to-menu-label"
                              id="demo-simple-select"
                              value={ jumpTo }
                              label="Jump To"
                              onChange={ handleJumpToChange }
                              size="small"
                              sx={ styles.whiteSelect }
                              inputProps={{
                                 classes: {
                                    icon: styles.whiteSelect.icon,
                                    root: styles.whiteSelect.root,
                                 },
                             }}
                           >
                              <MenuItem value='channelHeaderRef'>Top</MenuItem>
                              <MenuItem value='sealSectionRef'>Seal of Approval</MenuItem>
                              <MenuItem value='couponSectionRef'>Coupons & Links</MenuItem>
                              <MenuItem value='dropSectionRef'>Recent Drops</MenuItem>
                              <MenuItem value='storeSectionRef'>Shop</MenuItem>
                           </Select>
                           </FormControl>
                     </Grid>
                  ) }

                  { mode !== 'shabazz' && (
                  <Grid>
                     <Button
                        color="white"
                        variant="contained"
                        component={ Link }
                        to={'/shabazz'}
                        sx={ { fontWeight:'bold' } }
                     >
                        Visit My Channel
                     </Button>
                  </Grid>
                  ) }

                  { /*
                  <Grid>
                     <Box sx={ { display: { xs: 'none', md: 'block' } } }>
                     </Box>
                     <Box sx={ { display: { xs: 'block', md: 'none' } } }>
                        MM
                     </Box>
                  </Grid>
                  */ }
               </Grid>
            </Toolbar>
         </Container>
      </Box>
   )
} )

export default ShabazzToolbar