
import { useState, forwardRef, useEffect } from "react"
import Button from '@mui/material/Button'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import { Box, DialogContent, DialogTitle, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
// import Input from '@mui/material/Input'
// import InputAdornment from '@mui/material/InputAdornment'
import { useNavigate } from "react-router-dom"
// import { FormLabel } from '@mui/material'
// import { FormControlLabel } from '@mui/material'
import { InputLabel } from '@mui/material'
import { Select } from '@mui/material'
import { MenuItem } from '@mui/material'

import { useAuthContext } from "../../hooks/useAuthContext"

import FormControl from '@mui/material/FormControl'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import Badge from '@mui/material/Badge'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import Container from '@mui/material/Container'

import Tooltip from '@mui/material/Tooltip'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'

import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import LooksThreeIcon from '@mui/icons-material/Looks3'
import LooksFourIcon from '@mui/icons-material/Looks4'
import AddReactionIcon from '@mui/icons-material/AddReaction'
import BedtimeIcon from '@mui/icons-material/Bedtime'
import LightModeIcon from '@mui/icons-material/LightMode'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import RecommendIcon from '@mui/icons-material/Recommend'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
// import SearchIcon from '@mui/icons-material/Search'
import FavoriteIcon from '@mui/icons-material/Favorite'
import LanguageIcon from '@mui/icons-material/Language'

const Transition = forwardRef( function Transition( props, ref ) {
   return <Slide direction="down" ref={ ref } {...props} />
} )

export default function HempFilter( {
   products,
   filters,
   updateFilter,
   resetFilters,
   currentFilteredProducts,
   mode,
} ) {
   const { user } = useAuthContext()

   const navigate = useNavigate()
   
   const [ open, setOpen ] = useState( false );

   const handleClickOpen = () => {
     setOpen( true );
   };
 
   const handleClose = () => {
      setOpen( false )

      let forceStrainView = false

      // Check if there is a value for forceStrainViewDate in localStorage.
      // If it is more than 24 hours ago then set forceStrainView to true
      // and update localStorage with the new date.
      const forceStrainViewDate = localStorage.getItem('forceStrainViewDate')
      
      if ( forceStrainViewDate ) {
         const currentTime = new Date().getTime()
         const storedTime = new Date( forceStrainViewDate ).getTime()
         const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

         if ( currentTime - storedTime > twentyFourHours ) {
            forceStrainView = true
            localStorage.setItem('forceStrainViewDate', new Date().toISOString())
         }
      } else {
         // Set current time in localStorage
         localStorage.setItem('forceStrainViewDate', new Date().toISOString())
         forceStrainView = true
      }

      if ( forceStrainView ) {
         // Rediect to /cbd-flower/strains using browser router
         navigate( "/cbd-flower/strains" )
      }
   }

   const buttonTypeVariant = ( type ) => {
      return filters.type.includes(type) ? "contained" : "outlined"
   }

   const buttonTypeColor = ( type ) => {
      return filters.type.includes(type) ? '#ffffff' : ""
   }

   const buttonEffectVariant = ( effect ) => {
      return filters.effect.includes( effect ) ? "contained" : "outlined"
   }

   const buttonEffectColor = ( effect ) => {
      return filters.effect.includes( effect ) ? '#ffffff' : ""
   }

   const buttonEnviroVariant = ( effect ) => {
      return filters.enviro.includes( effect ) ? "contained" : "outlined"
   }

   const buttonEnviroColor = ( effect ) => {
      return filters.enviro.includes( effect ) ? '#ffffff' : ""
   }

   const addUniqueFilter = ( curArray, newItem ) => {
      const temp = [ ...curArray ]

      if ( newItem instanceof Array ) {
         newItem.forEach( item => {
            if ( !temp.includes( item ) ) {
               temp.push( item )
            } else {
               temp.splice( temp.indexOf( item ), 1 )
            }
         } )
      } else {
         if ( !temp.includes( newItem ) ) {
            temp.push( newItem )
         } else {
            temp.splice( temp.indexOf( newItem ), 1 )
         }
      }

      return temp
   }

   const numberOfFilters = () => {
      let num = 0

      if ( filters.type.length ) num += 1
      if ( ! filters.stock ) num += 1
      if ( filters.effect.length ) num += 1
      if ( filters.enviro.length ) num += 1
      if ( filters.shabazz ) num += 1
      if ( filters.new ) num += 1
      if ( filters.new ) num += 1
      if ( filters.fav ) num += 1
      if ( filters.intl ) num += 1

      return num
   }

   const styles = {
      'filterButton': {
         color: 'blue',
         m: .5,
         fontWeight: 'bold',
      },
      'filterButtonShake': {
         animationName: 'shake',
         animationDuration: '20s',
         animationDelay: '20s',
         animationIterationCount: 'infinite',
      }
   }

   let filterButtonStyles = styles.filterButton

   if ( ! numberOfFilters() ) {
      filterButtonStyles = { ...styles.filterButton, ...styles.filterButtonShake }
   }

   const getFilteredButtonText = () => {
      
      if ( numberOfFilters() === 0 ) {
         return 'Filters'
      }

      let text = `${ currentFilteredProducts.length } / ${ products.length }`

      
      if ( filters.stock ) {
         return `${ currentFilteredProducts.length } / ${ products.flat().filter( prod => prod.in_stock === true ).length }`
      }

      return text
   }

   return (
      <>
         <Box sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: { xs: 'space-between', sm: 'flex-end' }
         }}>
            <Box sx={ { display: { sm: 'none' } } }>
               <FormControl fullWidth>
                  <InputLabel id="view-by-label">View By</InputLabel>
                  <Select
                     labelId="view-by-label"
                     id="view-by"
                     value={ mode === 'farms' || mode === "home" ? 'farms' : 'strains' }
                     label="View By:"
                     autoWidth
                     sx={ { minWidth: 100 } }
                     size="small"
                     onChange={ event => {
                        navigate(`/cbd-flower/${ event.target.value }`)
                     } }
                  >
                     <MenuItem value={'farms'}>Farms</MenuItem>
                     <MenuItem value={'strains'}>Strains</MenuItem>
                  </Select>
               </FormControl> 
            </Box>

            <div>
               { /* I think I'm going to do search in the Strain view. * /
               {
                  // Kinda diffent ternary usage
                  ( searchOpen )
                     ?
                        <FormControl variant="standard" sx={{
                           width: { xs: '15ch', sm: '25ch' },
                           marginRight: '1em'
                        }}>
                           <Input
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       onClick={ handleSearchOpenClick }
                                    >
                                       <CloseIcon/>
                                    </IconButton>
                                 </InputAdornment>
                              }
                              value = { filters.text }
                              // onChange = { handleTextChange }
                              onChange = { ( e ) => updateFilter( 'text', e.target.value )}
                              label = "Search"
                              autoFocus = { true }
                           />
                        </FormControl>
                     :
                        <IconButton onClick = { handleSearchOpenClick } sx={ { marginRight: '.5em' } }>
                           <SearchIcon />
                        </IconButton>
               }
               */ }
   
               <Typography
                  component="span"
                  variant='body1'
                  fontWeight={ 'bold' }
                  sx={ { whiteSpace: 'nowrap' } }
               >
                  In Stock:
               </Typography>
               <Checkbox
                  size="small"
                  // onChange={ changeStockFilter }
                  onChange={ () => updateFilter( 'stock', ! filters.stock ) }
                  sx={{ mr: .5 }}
                  checked={ filters.stock }
               />
               <Badge
                  badgeContent={ numberOfFilters() }
                  color="orange"
               >
                  <Button
                     variant="contained"
                     onClick={ handleClickOpen }
                     color="darkBlue"
                     size="small"
                     sx={ filterButtonStyles }
                     startIcon={ <FilterAltIcon /> }
                  >
                     { getFilteredButtonText() }
                  </Button>
               </Badge>
            </div>

         </Box>
         <Dialog
            fullScreen
            open={ open }
            onClose={ handleClose }
            TransitionComponent={ Transition }
         >
            <DialogTitle component="div" sx={ { borderBottom: '1px solid #ccc' } }>
               <Container sx={ { display: 'flex' } }>
                  <Typography sx={ { flex: 1 } } variant="h5" component="div">
                     Select Filters
                  </Typography>
                  <IconButton
                     onClick={ handleClose }
                     aria-label="close"
                  >
                     <CloseIcon />
                  </IconButton>
               </Container>
            </DialogTitle>
            <DialogContent>
               <Container sx={ { paddingLeft: 0, paddingRight: 0 } }>
                  <Box>                     
                     <Box sx={ { pt: 3, pb: 3, borderBottom: '1px solid #ccc' } }>   
                        <Typography variant="h6" component="div" gutterBottom sx={ { display: 'inline-block' } }>
                           Chemotype:
                        </Typography>
                        <Tooltip title="Chemotype refers to a specific combination of chemical compounds, particularly cannabinoids and terpenes (such as THC and CBD), found in a cannabis plant strain, which influences its therapeutic and psychoactive effects.">
                           <IconButton>
                              <HelpCenterIcon />
                           </IconButton>
                        </Tooltip>

                        <Box>
                           <Button
                              variant={ buttonTypeVariant(1) }
                              onClick={ () => updateFilter( 'type', addUniqueFilter( filters.type, 1 ) ) }
                              color="orange"
                              size="medium"
                              sx={ { color: buttonTypeColor(1), m: .5, fontWeight: "bold" } }
                              startIcon={ <LooksOneIcon /> }
                           >
                              THCa
                           </Button>
                           <Button
                              variant={ buttonTypeVariant(2) }
                              onClick={ () => updateFilter( 'type', addUniqueFilter( filters.type, 2 ) ) }
                              color="primary"
                              size="medium"
                              sx={{ color: buttonTypeColor(2), m: .5, fontWeight: "bold" }}
                              startIcon={ <LooksTwoIcon /> }
                           >
                              THCa & CBD
                           </Button>
                           <Button
                              variant={ buttonTypeVariant(3) }
                              onClick={ () => updateFilter( 'type', addUniqueFilter( filters.type, 3 ) ) }
                              color="blue"
                              size="medium"
                              sx={{ color: buttonTypeColor(3), m: .5, fontWeight: "bold" }}
                              startIcon={ <LooksThreeIcon /> }
                           >
                              CBD
                           </Button>
                           <Button
                              variant={ buttonTypeVariant(4) }
                              onClick={ () => updateFilter( 'type', addUniqueFilter( filters.type, 4 ) ) }
                              color="darkBlue"
                              size="medium"
                              sx={{ color: buttonTypeColor(4), m: .5, fontWeight: "bold" }}
                              startIcon={ <LooksFourIcon /> }
                           >
                              CBG
                           </Button>
                        </Box>
                     </Box>

                     <Box sx={ { pt: 3, pb: 3, borderBottom: '1px solid #ccc' } }>
                        <Typography variant="h6" component="div" gutterBottom sx={ { display: 'inline-block' } }>
                           Effect:
                        </Typography>
                        <Tooltip title='"Daytime", "Head", "Energetic" are defined as sativa. "Night Time", "Body", and "Binge on the couch all night watching Arrested Development reruns" are defined indica.'>
                           <IconButton>
                              <HelpCenterIcon />
                           </IconButton>
                        </Tooltip>

                        <Box>
                           <Button
                              variant={ buttonEffectVariant( 'sativa' ) }
                              onClick={ () => updateFilter( 'effect', addUniqueFilter( filters.effect, [ 'sativa', 'sativa-leaning' ] ) ) }
                              color="orange"
                              size="medium"
                              sx={{ color: buttonEffectColor( 'sativa' ), m: .5, fontWeight: "bold" }}
                              startIcon={ <AddReactionIcon /> }
                           >
                              Sativa
                           </Button>
                           <Button
                              variant={ buttonEffectVariant( 'indica' ) }
                              onClick={ () => updateFilter( 'effect', addUniqueFilter( filters.effect, [ 'indica', 'indica-leaning' ] ) ) }
                              color="black"
                              size="medium"
                              sx={{ color: buttonEffectColor( 'indica' ), m: .5, fontWeight: "bold" }}
                              startIcon={ <BedtimeIcon /> }
                           >
                              Indica
                           </Button>
                           <Button
                              variant={ buttonEffectVariant( 'hybrid' ) }
                              onClick={ () => updateFilter( 'effect', addUniqueFilter( filters.effect, 'hybrid' ) ) }
                              color="primary"
                              size="medium"
                              sx={{ color: buttonEffectColor( 'hybrid' ), m: .5, fontWeight: "bold" }}
                              startIcon={ <SelfImprovementIcon /> }
                           >
                              Hybrid
                           </Button>
                        </Box>
                     </Box>

                     <Box sx={ { pt: 3, pb: 3, borderBottom: '1px solid #ccc' } }>
                        <Typography variant="h6" component="div" gutterBottom sx={ { display: 'inline-block' } }>
                           Environment:
                        </Typography>
                        <Tooltip title="Some people prefer indoor-grown hemp over greenhouse or outdoor but it comes at a premium price.">
                           <IconButton>
                              <HelpCenterIcon />
                           </IconButton>
                        </Tooltip>
                        <Box>
                           <Button
                              variant={ buttonEnviroVariant( "outdoor" ) }
                              onClick={ () => updateFilter( 'enviro', addUniqueFilter( filters.enviro, 'outdoor' ) ) }
                              color="orange"
                              size="medium"
                              sx={{ color: buttonEnviroColor( "outdoor" ), m: .5, fontWeight: "bold" }}
                              startIcon={ <LightModeIcon /> }
                           >
                              Outdoor
                           </Button>
                           <Button
                              variant={ buttonEnviroVariant( "greenhouse" ) }
                              onClick={ () => updateFilter( 'enviro', addUniqueFilter( filters.enviro, 'greenhouse' ) ) }

                              color="primary"
                              size="medium"
                              sx={{ color: buttonEnviroColor( "greenhouse" ), m: .5, fontWeight: "bold" }}
                              startIcon={ <WarehouseIcon /> }
                           >
                              Greenhouse
                           </Button>
                           <Button
                              variant={ buttonEnviroVariant( "indoor" ) }
                              onClick={ () => updateFilter( 'enviro', addUniqueFilter( filters.enviro, 'indoor' ) ) }
                              color="blue"
                              size="medium"
                              sx={{ color: buttonEnviroColor( "indoor" ), m: .5, fontWeight: "bold" }}
                              startIcon={ <WbIncandescentIcon /> }
                           >
                              Indoor
                           </Button>
                        </Box>
                     </Box>

                     <Box sx={ { pt: 3, pb: 3, borderBottom: '1px solid #ccc' } }>
                        <Typography variant="h6" component="div" gutterBottom sx={ { display: 'inline-block' } }>
                           More Filters:
                        </Typography>
                        <Tooltip title="Other filters that didn't fit into a neat category">
                           <IconButton>
                              <HelpCenterIcon />
                           </IconButton>
                        </Tooltip>
                        <Box>
                        <Button
                              variant={ filters.new ? "contained" : "outlined" }
                              onClick={ () => updateFilter( 'new', ! filters.new )}
                              color="darkBlue"
                              size="medium"
                              sx={ { color: filters.new ? "darkBlue" : "white", m: .5, fontWeight: "bold" }}
                              startIcon={ <FiberNewIcon /> }
                           >
                              New &amp; Restocked
                           </Button>
                           <Button
                              variant={ filters.sale ? "contained" : "outlined" }
                              onClick={ () => updateFilter( 'sale', ! filters.sale )}
                              color="primary"
                              size="medium"
                              sx={ { color: filters.sale ? "primary" : "white", m: .5, fontWeight: "bold" }}
                              startIcon={ <MonetizationOnIcon /> }
                           >
                              On Sale
                           </Button>
                           <Button
                              variant={ filters.shabazz ? "contained" : "outlined" }
                              onClick={ () => updateFilter( 'shabazz', ! filters.shabazz )}
                              color="darkBlue"
                              size="medium"
                              sx={ { color: filters.shabazz ? "darkBlue" : "white", m: .5, fontWeight: "bold" }}
                              startIcon={ <RecommendIcon /> }
                           >
                              Shabazz Approved
                           </Button>
                           { user && (
                              <Button
                                 variant={ filters.fav ? "contained" : "outlined" }
                                 onClick={ () => updateFilter( 'fav', ! filters.fav )}
                                 color="pink"
                                 size="medium"
                                 sx={ { color: filters.fav ? "primary" : "white", m: .5, fontWeight: "bold" }}
                                 startIcon={ <FavoriteIcon /> }
                              >
                                 My Favorites
                              </Button>
                           ) }
                           <Button
                              variant={ filters.intl ? "contained" : "outlined" }
                              onClick={ () => updateFilter( 'intl', ! filters.intl )}
                              color="darkBlue"
                              size="medium"
                              sx={ { color: filters.new ? "darkBlue" : "white", m: .5, fontWeight: "bold" }}
                              startIcon={ <LanguageIcon /> }
                           >
                              Intl. Shipping
                           </Button>
                        </Box>
                     </Box>

                     <Box sx={ { pt: 3, pb: 3 } }>
                        <Typography variant="h6" component="div" gutterBottom sx={ { display: 'inline-block' } }>
                           Product Availability:
                        </Typography>
                        <Tooltip title="This may be useful if you want to see any products that may soon become available">
                           <IconButton>
                              <HelpCenterIcon />
                           </IconButton>
                        </Tooltip>
                        <Box>
                           <Checkbox
                              size="small"
                              onChange={ () => updateFilter( 'stock', ! filters.stock ) }
                              sx={ { mr: 1 } }
                              checked={ !filters.stock }
                           />
                           <Typography
                              component="span"
                              variant='body1'
                              fontWeight={ 'bold' }
                           >
                              Include out of stock products
                           </Typography>
                        </Box>
                     </Box>
                  </Box>
               </Container>
            </DialogContent>
            <DialogActions sx={ { backgroundColor: '#eeeeee' } }>
               <Container
                  sx={ {
                     display: 'flex',
                     justifyContent: 'center',
                     backgroundColor: '#eeeeee',
                     paddingLeft: 0,
                     paddingRight: 0,
                     paddingTop: 1,
                     paddingBottom: 1
                  } }>
                  <Button
                     color="primary"
                     onClick={ handleClose }
                     aria-label="close"
                     startIcon={ <ViewModuleIcon /> }
                     variant="contained"
                     size="large"
                     sx={ { fontWeight: "bold" } }
                  >
                     View { currentFilteredProducts.length } Results
                  </Button>
                  <Button
                     edge="start"
                     color="darkBlue"
                     onClick={ () => {
                        resetFilters()
                        handleClose()
                     } }
                     aria-label="close"
                     startIcon={ <FilterAltOffIcon /> }
                     variant="contained"
                     sx={ { ml: 3, fontWeight: "bold" } }
                     size="large"
                  >
                     Reset
                  </Button> 
               </Container>
            </DialogActions>
         </Dialog>
      </>
   )
}
