import { Box, Toolbar, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Button from '@mui/material/Button'
import { Link as RouterLink } from 'react-router-dom'
import HempFilter from '../HempFilter/HempFilter'
import { useAuthContext } from '../../hooks/useAuthContext'
import { forwardRef } from 'react'
import { usePageContext } from '../../hooks/usePageContext'

export default forwardRef( function VendorProductFilter( {
   filters,
   updateFilter,

   resetFilters,
   products,
   userData,
   vendorList,
   filteredProducts,
   filterProducts,
   mode,
}, ref ) {
   
   const { authIsReady } = useAuthContext()

   const { scrollMarginTop, toolbarHeight } = usePageContext()

   return (
      <Box
         id="vendorFilter"
         ref={ ref }
         sx={{
            position: 'sticky',
            top: `${ scrollMarginTop }px`,
            zIndex: 3,
            backgroundColor: "#ffffff",
            borderBottom: '1px solid #ccc',
         }}
      >
         <Container
            maxWidth="xl"
         >
            <Toolbar
               disableGutters
               sx={{
                  justifyContent: 'space-between',
                  height: `${ toolbarHeight }px`,
               }}
            >
               { authIsReady && (
                  <Box sx={{
                     display: { xs: 'none', sm: 'flex' },
                     whiteSpace: 'nowrap',
                     alignItems: 'center',
                     height: `${ toolbarHeight }px`,
                  }}>
                     <Box>
                        <Button
                           component={ RouterLink }
                           to="/cbd-flower/farms"
                           variant={ mode === "farms" || mode === "home" ? "contained" : "outlined" }
                           sx={ { marginRight: '1rem', fontWeight: "bold" } }
                           size="small"
                        >
                           Farms
                        </Button>
                        <Button
                           component={ RouterLink }
                           to="/cbd-flower/strains"
                           variant={ mode === "strains" ? "contained" : "outlined" }
                           sx={ { marginRight: '1rem', fontWeight: "bold" } }
                           size="small"
                        >
                           Strains
                        </Button>
                     </Box>
                  </Box>
               ) }
               <Box sx={ { display: "flex", width: '100%' } }>
                  <HempFilter
                     resetFilters = { resetFilters }
                     updateFilter={ updateFilter }
                     currentFilteredProducts={ filteredProducts }
                     filters = { filters }
                     products={ products }
                     mode={ mode }
                  />
               </Box>
            </Toolbar>
         </Container>
      </Box>      
   )
} )
