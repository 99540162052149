import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useState, useEffect, useRef } from "react"
import { useCollection } from '../../hooks/useCollection'
import { useDocument } from '../../hooks/useDocument'
import { useAuthContext } from '../../hooks/useAuthContext'
import { usePageContext } from '../../hooks/usePageContext'

import MainContainer from '../../components/Layout/MainContainer/MainContainer'
import VendorProductFilter from './VendorProductFilter'

import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { collection, onSnapshot, query } from 'firebase/firestore'

import { NavLink as RouterLink, Link } from "react-router-dom"

import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import ProductModal from '../ProductModal'

import FarmsList from './FarmsList/FarmsList'
import StrainsList from './StrainsList/StrainsList'

import VendorLogoList from './VendorLogoList'
import Scroller from '../Layout/Scroller/Scroller'
import Grid from '@mui/material/Unstable_Grid2/Grid2'

import { useTheme } from '@mui/material/styles'

import ShabazzAffiliates from '../../pages/shabazz/ShabazzAffiliates'
import ShabazzToolbar from '../../pages/shabazz/ShabazzToolbar'

import HempHarvestHeaderBG from '../../pages/Home/hempHarvestHeaderBG2.jpg'

export default function VendorInventory( { mode = "farms", vendors = [] } ) {
   const { toolbarHeight, scrollMarginTop, coupons, headerBG, headerBGColor } = usePageContext()
   
   const theme = useTheme()

   const styles = {
      videoIframeWrapper: {
         padding: '56.25% 0 0 0',
         position: 'relative'
      },
      videoIframe: {
         position: 'absolute',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%'
      },
      logo: {
         marginRight: theme.spacing( 2 ),
      },
      title: {
         flexGrow: 1,
      },
      introSection: {
         padding: { xs: '0', sm: '2em' },
      },
      hempHarvestContainer: {
         backgroundImage: `url("${ HempHarvestHeaderBG }")`,
         backgroundSize: 'cover',
         backgroundPosition: 'top',
         borderLeft: '21px double #3D5125',
         borderRight: '21px double #3D5125',
         height: '600px',
      }
   }
   
   const { user } = useAuthContext()

   const sortVendors = ( vendors ) => {

      // Consider doing a random sort
      //const sortedVendors = vendors.sort( () => Math.random() - 0.5 )
      
      // Alphabetical
      const sortedVendors = vendors.sort( ( a, b ) => a.title.localeCompare( b.title ) )

      // Part of the Featured Fendor
      /*
      const featuredVendorIdx = vendors.findIndex( p => p.slug === 'neverwinterbotanicals' )

      if ( featuredVendorIdx !== -1 ) {
         const removed = sortedVendors.splice( featuredVendorIdx, 1 )
         sortedVendors.unshift( removed[0] )
      }
      */

      return sortedVendors
   }
   
   let vendorList = vendors

   // Get vendorList from DB
   let { documents: vendorListRef } = useCollection( 'vendorLists/mostPopular/vendors' )

   // If we didn't pass in vendors, use the ones from the DB
   if ( !vendorList.length && vendorListRef ) {
      vendorList = sortVendors( vendorListRef )
   }

   // Get user's data
   const { document: userData } = useDocument( 'users', user ? user.uid : '0' )

   const defaultFilters = {
      type: [],
      stock: true,
      effect: [],
      enviro: [],
      shabazz: false,
      new: false,
      sale: false,
      fav: false,
      intl: false,
      text: ""
   }

   const savedFilters = JSON.parse( localStorage.getItem( 'filters' ) ) || {}

   // merge saved filters with default filters with saved filters taking precedence
   const mergedFilters = { ...defaultFilters, ...savedFilters }

   const [ filters, setFilters ] = useState( mergedFilters )

   // Generalized function to updating filter state.
   const updateFilter = ( key, value ) => {
      setFilters( prevFilters => {
         
         const newFilters = {
            ...prevFilters,
            [ key ]: value
         }

         localStorage.setItem( 'filters', JSON.stringify( newFilters ) )
         
         return newFilters
      } )
   }

   const [ productModalOpen, setProductModalOpen ] = useState( false )
   const [ friendsModalOpen, setFriendsModalOpen ] = useState( false )
   const [ productModalData, setProductModalData ] = useState( false )
   const [ products, setProducts ] = useState( [] )
   const [ filteredProducts, setFilteredProducts ] = useState( [] )
   const [ giveawayModalOpen, setGiveawayModalOpen ] = useState( false )
   
   // Product Card thumbnail/title
   const handleClickOpen = ( product ) => {
      // GA4 Event
      window.dataLayer.push( {
         event: 'product_card',
         uid: user ? user.uid : '',
         vendor_slug: product.vendorSlug,
         vendor_name: product.vendorName,
         product_id: product.id,
         product_title: product.title
      } )
      setProductModalData( product )
      setProductModalOpen( true );
   };

   const handleFriendsClickOpen = () => {
      setFriendsModalOpen( true )
   }
  
   const handleClose = () => {
      setProductModalOpen( false )
      setFriendsModalOpen( false )
      setGiveawayModalOpen( false )
   };

   const handleFav = ( product ) => {
      let favsArray = []
      
      if ( userData.favs && userData.favs.length ) {
         favsArray = userData.favs
         
         if ( ! favsArray.includes( product.id ) ) {
            favsArray.push( product.id )
         } else {
            favsArray.splice(favsArray.indexOf( product.id ), 1);
         }
      } else {
         favsArray.push( product.id )
      }
      
      try {
         updateDoc( doc ( db, 'users', user.uid ), { favs: favsArray } )
      } 
      catch ( error ) {
         console.log('error', error)
      }
   }

   const resetFilters = () => {
      localStorage.removeItem('filters')
      setFilters( defaultFilters )
   }

   const offset_timestamp = (Date.now() / 1000) - (60 * 60 * 24 * 3)

   const filterProducts = ( product ) => {
      /*
      const filters = {
         type: [],
         stock: true,
         effect: [],
         enviro: [],
         shabazz: false,
         new: false,
         sale: false,
         fav: false,
         intl: false,
         text: ""
      }
      */

      if ( ! product.in_stock && filters.stock ) return false

      // Get rid of any products that need to be verified and dropped
      if ( product.queue && ( product.queue === 'new' || product.queue === 'restock' ) ) return false

      if ( filters.new ) {
         let newOrRestocked = false
   
         if ( product.new_date && product.new_date.seconds > offset_timestamp ) newOrRestocked = true
         if ( product.restock_date && product.restock_date.seconds > offset_timestamp ) newOrRestocked = true

         return newOrRestocked
      }

      if ( filters.effect.length && ! filters.effect.includes( product.effect ) ) return false
      if ( filters.enviro.length && ! filters.enviro.includes( product.enviro ) ) return false
      if ( filters.type.length && ! filters.type.includes( product.hemp_type ) ) return false
      if ( filters.shabazz && ! product.ssoa ) return false
      if ( filters.sale && ! product.on_sale ) return false
      if ( filters.fav && userData.favs.length && ! userData.favs.includes( product.id ) ) return false
      if ( filters.text && ! product.title.toLowerCase().includes( filters.text.toLowerCase() ) ) return false
      if ( filters.intl && ! product.intlShipping ) return false

      return true
   }

   useEffect( () => {
      // Wait until we get the vendor list back from DB
      if ( vendorList && ! products.length ) {
         let q = null

         // Get each vendor's products and put them into a products array
         vendorList.forEach( vendor => {
            q = query(
               collection( db, `vendors/${ vendor.slug }/products` ),
               //where( 'queue_date', '>', oneWeek ),
               //where( 'queue', '==', 'drop' )
            )

            // Realtime data
            const unsubscribe = onSnapshot( q, snapshot => {
               snapshot.forEach( doc => {
                  setProducts( oldArray => {

                     const productArray = [
                        // Get rid of any duplicates since we're using live data
                        ...oldArray.filter( el => el.id !== doc.id ),
                        {
                           ...doc.data(),
                           id: doc.id,
                           vendorName: vendor.title,
                           vendorSlug: vendor.slug,
                        }
                     ]

                     const fProducts = productArray.flat().filter( ( product ) => { 
                        return filterProducts( product )
                     } )

                     setFilteredProducts( fProducts )

                     return productArray
                  } )
               } )
            } )

            // Clean up function for when component unmounts
            // Don't remember what that does.
            return () => unsubscribe()
         } )
      }

      if ( products.length ) {
         const filtered = products.flat().filter( ( product ) => { 
            return filterProducts( product )
         } )

         setFilteredProducts( filtered )
      }      
      
   }, [ vendorList, filters, mode ] )

   const newRestockedProducts = filteredProducts.filter( product => {
      if ( product.new_date && product.new_date.seconds > offset_timestamp ) return true
      if ( product.restock_date && product.restock_date.seconds > offset_timestamp ) return true
      return false
   } )

   const refs = {
      channelHeaderRef: useRef( null ),
      sealSectionRef: useRef( null ),

      // couponSectionScrollTargetRef: useRef( null ),
      couponSectionRef: useRef( null ),
      dropSectionRef: useRef( null ),
      storeSectionRef: useRef( null ),
   }
   
   const appBarRef = useRef( null )

   // Put this back in the ShabazzToolbar component
   const scrollToSection = ( ref ) => {
      if ( ref ) {
         ref.scrollIntoView( { behavior: 'smooth' } )
      }
   }

   return (
      <>
         <MainContainer
            fullWidth={ true }
            sx = { {
               paddingLeft: { xs: 0, sm: 'inherit' },
               paddingRight: { xs: 0, sm: 'inherit' },
               border: '5px solid red',
            } }
            ref = { appBarRef }
         >
            <Box id="main">
               { /* Dis shit all needs to go into Home component */ }
               { /* Shabazz Header */}
               { headerBG && (
                  <>
                  <Box
                     id="channelHeader"
                     ref={ refs.channelHeaderRef }
                     sx={ {
                        backgroundColor: headerBGColor ? headerBGColor : '#000000',
                        zIndex: 1,
                        scrollMarginTop: '65px'
                        /* 
                        '&.headerStuck': {
                           border: '5px solid pink'
                        }
                        */
                     } }
                  >
                     <Container maxWidth="xl" >
                        <img src={ headerBG } alt="Shabazz's Channel" width="100%" style={ { verticalAlign: 'bottom' } } />
                     </Container>
                  </Box>
                     <ShabazzToolbar
                        mode={ mode }
                        appBarRef={ appBarRef }
                        ref={ refs }
                        scrollToSection={ scrollToSection }
                     />
                  </>
               ) }

               { mode === "shabazz" && (
                  <>
                  { /* Intro text */}
                  
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Grid
                           container
                           spacing={ 3 }
                           justifyContent="center"
                           alignItems="center"
                           sx={ styles.introSection }
                        >
                           <Grid xs={ 12 } md={ 12 }>
                              <Typography variant='h1' component={ 'h1' }
                                 sx={ {
                                 fontFamily: "'Kalam', cursive",
                                 fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                                 fontWeight: '400',
                                 textAlign: 'center',
                                 paddingTop: '2rem',
                                 color: 'secondary.main',
                                 maxWidth: '1200px',
                                 marginLeft: 'auto',
                                 marginRight: 'auto',
                                 marginBottom: '1rem'
                              } }>
                                 Welcome to Shabazz's Channel
                              </Typography>
                              <Typography
                                 variant='subtitle1'
                                 sx={{
                                    textAlign: 'center',
                                    padding: '0 1rem',
                                    fontSize: { 'sm': '1rem', 'md': '1.2rem', 'lg': '1.3rem' },
                                 }}
                              >
                                 I&apos;m passionate about cannabis, from the plant itself to the innovative work of farmers and scientists. Creating videos about CBD flower allows me to raise awareness and help consumers make smart choices. I’m dedicated to this growing industry and the positive changes it can bring. My goal is to introduce CBD to a broader market so others can experience its benefits, just like I have. Thanks for joining me on this journey.
                              </Typography>
                           </Grid>
                           { /*
                           <Grid xs={ 12 } md={ 6 }>
                              <Box sx={ styles.videoIframeWrapper }>
                                 <Box
                                    component="iframe"
                                    title="videos"
                                    src="https://player.vimeo.com/video/270503221?h=dbfcd48233"
                                    sx={ styles.videoIframe }
                                    frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                 />
                              </Box><script src="https://player.vimeo.com/api/player.js"></script>
                           </Grid>
                            */}
                        </Grid>
                     </Container>
                  </Box>

                  <Box
                     id="seal"
                     ref={ refs.sealSectionRef }
                     sx={ {
                        paddingBottom: '1rem',
                        backgroundColor: "grey.main",
                        scrollMarginTop: scrollMarginTop
                     } }>
                     <Scroller
                        handleModalClickOpen={ handleClickOpen }
                        handleFriendsClickOpen={ handleFriendsClickOpen }
                        userData={ userData }
                        handleFav={ handleFav }
                        products={ products }
                        scrollToTopButton={ false }
                        headerTitle="Awarded the Shabazz Seal of Approval"
                        
                        filteredProducts={ filteredProducts.filter( product => product.ssoa && product.ssoa === true ) }
                        // filteredProducts={ newRestockedProducts }
                        vendorList = { vendorList || [] }
                        headerBackgroundColor = 'secondary.main'
                     />
                  </Box>

                  <ShabazzAffiliates ref={ refs.couponSectionRef } />

                  </>
               ) }

               { /* Don't like this hard coded but don't want to redesign VendorInventory right now  */ }
               { mode === "home" && (
                  <>
                  { /* Intro text */}
                  { /*
                     <Box sx={{ backgroundColor: "secondary.main" }}>
                        <Container maxWidth="xl" sx={ styles.hempHarvestContainer }>
                        </Container>
                     </Box>
                  */ }
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Typography variant='h1' component={ 'h1' }
                           sx={ {
                           fontFamily: "'Kalam', cursive",
                           fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                           fontWeight: '400',
                           textAlign: 'center',
                           paddingTop: '2rem',
                           color: 'secondary.main',
                           maxWidth: '1200px',
                           marginLeft: 'auto',
                           marginRight: 'auto'
                        } }>
                           Farm-Direct CBD Flower From Top US Hemp Farms.
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }

               { /* Recent Drops! */}
               { ( mode === "home" || mode === 'shabazz' ) && (
                     <Box pb={ 2 } sx={ { backgroundColor: "grey.main" } }>
                        <Scroller
                           handleModalClickOpen={ handleClickOpen }
                           handleFriendsClickOpen={ handleFriendsClickOpen }
                           userData={ userData }
                           handleFav={ handleFav }
                           products={ products }
                           scrollToTopButton={ false }
                           headerTitle="Recent CBD Flower Drops!"
                           filteredProducts={ newRestockedProducts }
                           vendorList = { vendorList || [] }
                           headerBackgroundColor = 'secondary.main'
                           id="drops"
                           ref={ refs.dropSectionRef }
                        />
                     </Box>
               ) }

               <Box
                  ref = { refs.storeSectionRef }
                  sx={ { scrollMarginTop: '130px' } }
                  id="storeSection"
               >

               { /* Filter Bar */}
               <VendorProductFilter // need to i,mplement scroll mt
                  filters = { filters}
                  updateFilter = { updateFilter }
                  filterProducts = { filterProducts }
                  resetFilters = { resetFilters }
                  products = { products }
                  filteredProducts = { filteredProducts }
                  userData = { userData }
                  vendorList = { vendorList || [] }
                  mode = { mode }
               />

               { /* Still don't want to refactor it. Maybe pass this as children? */ }
               { mode === "strains" && (
                  <>
                  { /* Intro text */}
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Typography variant='h1' component={ 'h1' }
                           sx={ {
                           fontFamily: "'Kalam', cursive",
                           fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                           fontWeight: '400',
                           textAlign: 'center',
                           paddingTop: '2rem',
                           paddingBottom: '2rem',
                           color: 'secondary.main',
                           maxWidth: '1200px',
                           marginLeft: 'auto',
                           marginRight: 'auto'
                        } }>
                           Shop the Best Farm-Direct CBD Flower from Leading Cultivars Nationwide
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }

               { /* Still don't want to refactor it. Maybe pass this as children? */ }
               { mode === "farms" && (
                  <>
                  { /* Intro text */}
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container
                        maxWidth="xl"
                     >
                        <Typography variant='h1' component={ 'h1' }
                           sx={ {
                           fontFamily: "'Kalam', cursive",
                           fontSize: { 'sm': '1.5rem', 'md': '2rem', 'lg': '2.5rem' },
                           fontWeight: '400',
                           textAlign: 'center',
                           paddingTop: '2rem',
                           paddingBottom: '2rem',
                           color: 'secondary.main',
                           maxWidth: '1200px',
                           marginLeft: 'auto',
                           marginRight: 'auto'
                        } }>
                           Shop the Top CBD Farms and Hemp Growers Across the Nation.
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }

               { /* Vendor Logos */ }
               { /* List of farms with inventory */ }
               { ( mode ==="home" || mode === "farms" || mode === "shabazz" ) && (
                  <>
                  <VendorLogoList
                     vendorList = { vendorList }
                     filteredProducts = { filteredProducts }
                     products = { products }
                  />
      
                  <FarmsList
                     handleModalClickOpen={ handleClickOpen }
                     handleFriendsClickOpen={ handleFriendsClickOpen }
                     userData={ userData }
                     handleFav={ handleFav }
                     products={ products }
                     filteredProducts={ filteredProducts }
                     vendorList = { vendorList || [] }
                  />
                  </>
               )}

               { /* List of strains*/ }
               { mode === "strains" && (
                  <>
                  <StrainsList
                     handleModalClickOpen={ handleClickOpen }
                     handleFriendsClickOpen={ handleFriendsClickOpen }
                     userData={ userData }
                     handleFav={ handleFav }
                     products={ products }
                     filteredProducts={ filteredProducts }
                     vendorList = { vendorList || [] }
                     filters = { filters }
                     updateFilter = { updateFilter }
                  />
                  </>
               ) }

               { mode === "vendor" && (
                  <FarmsList
                     handleModalClickOpen={ handleClickOpen }
                     handleFriendsClickOpen={ handleFriendsClickOpen }
                     userData={ userData }
                     handleFav={ handleFav }
                     products={ products }
                     filteredProducts={ filteredProducts }
                     vendorList = { vendorList || [] }
                  />
               ) }

               { /* Farm Profile */ }
               { /*
               { mode === "farmProfile" && (
                  <>
                  <Box pt={2} sx={{ backgroundColor: "grey.main" }}>
                     <Container maxWidth="xl">
                        <Typography variant='h5' component='h1'>
                           Stoney Branch Farms
                        </Typography>
                        <Typography variant='subheader1'>
                           Stoney Branch is a family of 5th generation farmers using their knowledge & experience from the Colorodo cannabis market to cultivate craft CBD hemp flower in Illinois. All of their flower is grown, cured & packaged in-house, farm-to-flower.
                        </Typography>
                     </Container>
                  </Box>
                  </>
               ) }
               */}
            </Box>

            </Box>
         </MainContainer>
         
         <ProductModal
            open = { productModalOpen }
            handleClose = { handleClose }
            favsArray = { userData && userData.favs }
            handleFriendsClickOpen = { handleFriendsClickOpen }
            handleModalClickOpen = { handleClickOpen }
            product = { productModalData }
         />
         
         <Dialog
            open={ friendsModalOpen }
            onClose={ handleClose }
            aria-labelledby="friendsModal"
            aria-describedby="friendsModalDescription"
         >
            <DialogTitle id="friendsModal">
               Join Hempstok
            </DialogTitle>
            <DialogContent>
               <DialogContentText component='div' id="friendsModalDescription">
                  Get discounts, enter giveaways, and save your favorite strains. Join the Hempstok platform for free.
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  variant="contained"
                  color="primary"
                  component={ RouterLink }
                  to="/signup"
                  autoFocus
               >
                  Join Hempstok
               </Button>
            </DialogActions>
         </Dialog>

         <Dialog
            open={ giveawayModalOpen }
            onClose={ handleClose }
            aria-labelledby="giveawayRules"
            aria-describedby="giveawayModalDescription"
         >
            <DialogTitle id="giveawayRules">
               Hempstok Giveaway - Terms and Conditions
            </DialogTitle>
            <DialogContent>
               <DialogContentText component='div' id="giveawayModalDescription">
                  <Typography>
                     <ol>
                        <li>Eligibility: Participants must be 18 years or older and residents of the United States.</li>
                        <li>Entry Requirements: To enter, participants must use Hempstok's coupon code and complete the purchase before March 1st, 2024. A Hempstok account is required to participate in giveaways. Participants are entered automatically once entry requirements are met.</li>
                        <li>Giveaway Item: The prize consists of one ounce of hemp flower. No substitutions or cash alternatives will be provided.</li>
                        <li>Entry Deadline: All purchases must be completed by 11:59 PM (Pacific Time) on January 31, 2024, to be eligible for the giveaway.</li>
                        <li>Winner Selection: The winner will be randomly selected from eligible entries and announced within 7 days of the contest closing date.</li>
                        <li>Notification: The winner will be notified via the email registered with Hemptok. If no response is received within 7 days, an alternate winner will be chosen.</li>
                        <li>Publicity: By participating, entrants agree to allow Hempstok to use their name and likeness for promotional purposes without additional compensation.</li>
                        <li>Hempstok's Right to Modify: Hempstok reserves the right to modify, suspend, or terminate the contest at any time without notice, for any reason.</li>
                        <li>Compliance with Laws: Participants must comply with all federal, state, and local laws and regulations.</li>
                        <li>Release of Liability: By participating, entrants release Hempstok from any liability for losses, damages, or injuries arising from participation in the contest.</li>
                     </ol>
                  </Typography>
                  <Typography>
                     By entering the giveaway, participants agree to these terms and conditions.
                  </Typography>
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  variant="contained"
                  color="primary"
                  onClick={ handleClose }
               >
                  Close
               </Button>
            </DialogActions>
         </Dialog>
      </>
   )
}
