import { Box } from "@mui/material"

const Page = ( { cData, children } ) => {
   return (
         <Box id="Toppiest">
            { children }
         </Box>
   )
}

export default Page