import { useTheme } from '@mui/material/styles'
import { Box, Paper, Typography, Link } from '@mui/material'
import RenderThumbnail from './RenderThumbnail'
import { useAuthContext } from '../hooks/useAuthContext'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import { usePageContext } from '../hooks/usePageContext'

export default function ProductCard( {
   product,
   handleModalClickOpen,
   handleFriendsClickOpen,
   userData,
   handleFav,
   vendorData,
   vendorLogo, // Show vendor logo¸
   vendorName,
} ) {
   const { coupons } = usePageContext()

   const vendorLogoURL = ( vendorData && vendorData.logoURL ) || ''

   const theme = useTheme()
   const { user } = useAuthContext()

   const styles = {
      productContainer: {
         bgcolor: '#ffffff',
         height: "100%",
         position: 'relative',
         display: 'flex',
         flexDirection: 'column',
         cursor: 'pointer',
      },
      productTitleWrapper: {
         display: 'flex',
         alignItems: 'center',
         height: "100%",
         justifyContent: 'center',
         width: '100%',
      },
      productTitleBox: {
         flex: '1 1 100%',
         minWidth: 0
      },
      productTitle: {
         textAlign: "center",
         fontWeight: "bold",
         marginBottom: '0',
         lineHeight: '1.5rem',
         [theme.breakpoints.down('md')]: {
            fontSize: '.8rem',
         },
         // color: product.on_sale ? 'primary.main' : 'inherit',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
      },
      productVendor: {
         textAlign: "center",
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
      },
      onSaleBorder: {
         boxShadow: "0px 0px 21px 5px rgba(123,170,69,0.7)",
      },
      onSaleTitle: {
         // backgroundColor: '#7baa45',
         color: 'primary.main',
      },
      'couponBox': {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         padding: '.3rem 0'
      },
      'couponIcon': {
         fontSize: '1rem',
         color: '#ffffff',
         marginRight: '7px'
      },
      'couponText': {
         textAlign: 'center',
         color: '#ffffff',
         fontSize: '.7rem',
         fontWeight: 'bold'
      }
   }

   
   
   const cDataVendor = coupons[ product.vendorSlug ]
   let coupon = null

   if ( cDataVendor && cDataVendor.couponCode ) {
      coupon = {
         code: cDataVendor.couponCode,
         discount: cDataVendor.discount,
      }
   }

   return (
      <Paper
         elevation={ 1 }
         style={ styles.productContainer }
         sx={ product.on_sale ? styles.onSaleBorder : {} }
      >
         <RenderThumbnail
            favsArray = { userData && userData.favs }
            handleFav = { handleFav }
            product = { product }
            loggedIn = { user }
            handleFriendsClickOpen = { handleFriendsClickOpen }
            handleModalClickOpen = { handleModalClickOpen }
         />

         { /* Product Title */ }

            <Box
               p={ 1 }
               sx={ styles.productTitleWrapper }
               onClick={ () => { handleModalClickOpen( product ) } }
            >
               <Box sx={ styles.productTitleBox }>
                  <Link
                     href={ product.profile_url }
                     color="inherit"
                     underline="none"
                     rel="nofollow"
                     onClick={ ( e ) => {
                        e.preventDefault()
                     } }
                  >
                     <Typography
                        variant = "body1"
                        sx = { styles.productTitle }
                        // onClick={ () => { handleModalClickOpen( product ) } }
                     >
                        { product.title }
                     </Typography>

                     { vendorName && (
                        <Typography
                           variant="caption"
                           display="block"
                           sx = { styles.productVendor }
                        >
                           { vendorName }
                        </Typography>
                     ) }
                  </Link>
               </Box>
            </Box>

         { vendorLogo && vendorLogoURL && (
            <Box sx={ { textAlign: 'center' } }>
               <Box
                  component="img"
                  sx={ { height: '50px' } }
                  src={ vendorLogoURL }
               />
            </Box>
         ) }

         { ( coupon || product.on_sale ) && (
            <Box
               sx={ { ...styles.couponBox, backgroundColor: product.on_sale ? '#7baa45' : '#3d5125' } }
               onClick={ () => { 
                  if ( handleModalClickOpen ) {
                     handleModalClickOpen( product )
                  }
                  else {
                     return false
                  }
               } }
            >
               <LocalOfferIcon sx={ styles.couponIcon } /> 
               <Typography sx={ styles.couponText }>
                  { product.on_sale ? 'ON SALE!' : `SHOP ${ coupon.discount }` }
               </Typography>
            </Box>
         ) }
      </Paper>
) }
