import { useState, useEffect } from "react"
import { useSignup } from '../hooks/useSignup'
import { Box, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'

import { sendPasswordResetEmail } from "firebase/auth"

import { useLogin } from '../hooks/useLogin'

import { auth } from "../firebase/config"
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'

import { NavLink as RouterLink } from "react-router-dom"

export default function InlineAccountAccess( { setShowForm } ) {
   const [ showPassword, setShowPassword ] = useState( false )

   const handleClickShowPassword = () => setShowPassword((show) => !show)
   
   const styles = {
      // Not doing anything
      input: {
         "& .MuiFilledInput-root": {
            background: "#ffffff"
         }
      },
      actionBox: {
         backgroundColor: '#ffffff',
         padding: 3,
         borderRadius: '7px'
      },
   }

   const handlePasswordReset = () => {
      
      if ( !email ) {
         setFormError('Please enter a valid email.')
         return false
      }
      
      sendPasswordResetEmail(auth, email)
         .then(() => {
            alert('Check your email for a link to reset your password.')
         })
         .catch(( error ) => {
            setFormError( renderErrorMessage( error.code ) )
      } )
   }
   
   const [ name, setName ] = useState('')
   const [ email, setEmail ] = useState('')
   const [ password, setPassword ] = useState('')
   const { errorCode: signUpErrorCode, signup, isPending: signUpIsPending } = useSignup()
   const { errorCode: loginErrorCode, login, isPending: loginIsPending } = useLogin()
   const [ formError, setFormError ] = useState( null )
   const [ accountType, setAccountType ] = useState( null )

   const renderErrorMessage = ( errorCode ) => {
      console.log('errorCode', errorCode)
      
      switch( errorCode ) {
         case 'auth/user-not-found':
            return 'No users exist with that email'
         case 'auth/wrong-password':
            return 'Wrong password'
         case 'auth/invalid-email':
            return 'Email address is invalid'
         case 'auth/weak-password':
            return 'Choose a more secure password'
         case 'auth/email-already-in-use':
            return 'Email already registered. Try to login or reset password.'
         case null:
            return ''
         default:
            return 'Something went wrong'
      }
   }

   useEffect( () => {
      setFormError( renderErrorMessage( loginErrorCode ) )
      setFormError( loginErrorCode )
   }, [ loginErrorCode ] )

   useEffect( () => {
      setFormError( renderErrorMessage( signUpErrorCode ) )
      setFormError( signUpErrorCode )
   }, [ signUpErrorCode ] )

   const handleLoginSubmit = ( e ) => {
      e.preventDefault()
      setFormError('')

      if ( !email || !password ) {
         setFormError('Please enter a valid email and password.')
      } else {
         login( email, password )
      }
   }

   const handleSignUpSubmit = ( e ) => {
      e.preventDefault()
      setFormError('')

      if ( !email || !password || !name ) {
         setFormError('Please enter a valid name, email, and password.')
      } else {
         signup( email, password, name )
      }
   }

   const handleEmailSubmit = async ( e ) => {
      e.preventDefault()
      setFormError('')

      // Maybe put in some password strength requirements?
      if ( !email ) {
         setFormError('Please enter a valid email')
      } else {
         try {
            // Built to fail! Attempt to log in with a wrong password
            // Error message returned will state if we have a valid email
            await signInWithEmailAndPassword( auth, email, 'password' )
         } catch( err ) {
            console.log('err', err.code )
            
            if ( err.code === 'auth/wrong-password' ) {
               setAccountType( 'login' )
               // console.log( 'Email Found!' )
               // Set email state and show the password button
            } else {
               setAccountType( 'signup' )
               // †his is new user. Signup fields.
               // console.log( "Email Not Found" )
            }
         }
      }
   }

   let formAction = null
   let formFields = null

   if ( accountType === 'login') {
      formAction = handleLoginSubmit
      formFields = (
         <>
            <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
            <InputLabel htmlFor="email">Email address</InputLabel>
            <FilledInput
               id="email"
               onChange={ ( e ) => setEmail( e.target.value )}
               value={ email }
               autoComplete="email"
               type="email"
               readOnly={ true }
            />
            </FormControl>
            <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
               <InputLabel htmlFor="password">Password</InputLabel>
               <FilledInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={ ( e ) => setPassword( e.target.value )}
                  value={ password }
                  sx={ styles.input }
                  autoComplete="current-password"
                  endAdornment={
                     <InputAdornment position="end">
                        <IconButton
                           aria-label="toggle password visibility"
                           onClick={ handleClickShowPassword }
                           edge="end"
                        >
                           { showPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                     </InputAdornment>
                  }
               />
            </FormControl>
            <Button variant="contained" size="medium" type="submit">
               Login
            </Button>
            <Button variant="text" size="medium" color="secondary" sx={ { marginRight: 1, marginLeft: 1 } } onClick={ handlePasswordReset }>
               Reset Password
            </Button>
            <Button variant="text" size="medium" color="secondary" onClick={ () => setShowForm( false ) }>
               Cancel
            </Button>
         </>
      )
   } else if ( accountType === 'signup' ) {
      formAction = handleSignUpSubmit
      formFields = (
         <>
            <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
               <InputLabel htmlFor="email">Email address</InputLabel>
               <FilledInput
                  id="email"
                  onChange={ ( e ) => setEmail( e.target.value )}
                  value={ email }
                  autoComplete="email"
                  type="email"
                  readOnly={ true }
               />
            </FormControl>
            <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
               <InputLabel htmlFor="name">Name</InputLabel>
               <FilledInput
                  id="name"
                  onChange={ ( e ) => setName( e.target.value )}
                  value={ name }
                  autoComplete="name"
               />
            </FormControl>
            <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
               <InputLabel htmlFor="password">Password</InputLabel>
               <FilledInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={ ( e ) => setPassword( e.target.value )}
                  value={ password }
                  sx={ styles.input }
                  autoComplete="new-password"
                  endAdornment={
                     <InputAdornment position="end">
                        <IconButton
                           aria-label="toggle password visibility"
                           onClick={ handleClickShowPassword }
                           edge="end"
                        >
                           { showPassword ? <VisibilityOff /> : <Visibility /> }
                        </IconButton>
                     </InputAdornment>
                  }
               />
            </FormControl>
            <Button variant="contained" size="medium" type="submit">
               Join
            </Button>
            <Button variant="text" size="medium" color="secondary" onClick={ () => setShowForm( false ) }>
               Cancel
            </Button>
         </>
      )
   } else {
      formAction = handleEmailSubmit
      formFields = (
         <>
            <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
               <InputLabel htmlFor="email">Enter Your Email Address</InputLabel>
               <FilledInput
                  id="email"
                  onChange={ ( e ) => setEmail( e.target.value )}
                  value={ email }
                  autoComplete="email"
                  type="email"
               />
            </FormControl>
            <Button variant="contained" size="medium" type="submit" sx={ { marginRight: 2 } }>
               Next
            </Button>
            <Button variant="text" size="medium" color="secondary" onClick={ () => setShowForm( false ) }>
               Cancel
            </Button>
         </>
      )
   }

   return (
      <form onSubmit={ formAction }>
         { formFields }
         { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
      </form>
   )
}
