import VendorInventory from '../../components/VendorInventory/VendorInventory'
import { Helmet } from "react-helmet"
// import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import MailingListSignUp from '../../components/MailingListSignUp/MailingListSignUp'

import { Navigate } from 'react-router-dom'

import defaultCoupons from '../../coupons/defaultCoupons'

import Page from '../../components/Layout/Page/Page'
import { usePageContext } from '../../hooks/usePageContext'

// import InviteModal from '../../components/InviteModal/InviteModal'

export default function Home() {
   const { channel } = usePageContext()
   const { user } = useAuthContext()

   // Just to get this working for now.
   let redirect = false

   if ( channel === 'shabazzstok' ) {
      redirect = true
   }

   return (
      <>
         { redirect && <Navigate replace to="/shabazz" />}
         <Page>
            <Helmet>
               <meta charSet="utf-8" />
               <title>Farm-Direct CBD Flower</title>
               <link rel="canonical" href="https://hempstok.com" />
            </Helmet>
            
            <VendorInventory mode="home" />

            <MailingListSignUp />

            { /*
            { !user && ( 
               <InviteModal />
            ) }
            */}
         </Page>
      </>
   )
}
