import { useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'

import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'

export const useLogin = () => {
   const [ isCancelled, setIsCancelled ] = useState( false )
   const [ errorMessage, setErrorMessage ] = useState( null )
   const [ errorCode, setErrorCode ] = useState( null )
   const [ isPending, setIsPending ] = useState( false )
   const { dispatch } = useAuthContext()

  const login = ( email, password ) => {
   setErrorMessage( null )
   setErrorCode( null )
   setIsPending( true )

   signInWithEmailAndPassword( auth, email, password )
      .then( ( res ) => {
         dispatch( { type: 'LOGIN', payload: res.user } )

         if ( !isCancelled ) {
            setIsPending( false )
            setErrorMessage( null )
            setErrorCode( null )
         }
      } )
      .catch( ( err ) => {
         if ( !isCancelled ) {
            setErrorMessage( err.message )
            setErrorCode( err.code )
            setIsPending( false )
         }
      } )
  }

   useEffect( () => {
      return () => setIsCancelled( true )
   }, [])

  return { errorMessage, errorCode, login, isPending }
}
