import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { Helmet } from "react-helmet"

import Button from '@mui/material/Button'

import { Link, NavLink as RouterLink } from "react-router-dom"

import MainContainer from '../components/Layout/MainContainer/MainContainer'

// import { useTheme } from "@mui/material/styles"

export default function About() {
   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <title>About Hempstok - The Hemp Flower Marketplace</title>
            <link rel="canonical" href="https://hempstok.com/about" />
         </Helmet>
         <MainContainer fullWidth={ true }>
            <Box sx={{
               position: 'sticky',
               top: { md: '65px', xs: '56px', sm: '64px'},
               zIndex: 3,
               backgroundColor: "#ffffff",
               borderBottom: '1px solid #ccc',
            }}
            >
               <Container
                  maxWidth="xl"
                  sx={ { display: 'flex', pt: 2, pb: 2, justifyContent: 'center', gap: 3 } }
               >
                  <Typography
                     component="p"
                     variant="h5"
                  >
                     Let's Be Friends!
                  </Typography>
                  <Box>
                     <Button
                        variant="contained"
                        component={ RouterLink }
                        to="/signup"
                     >
                        Join Hempstok
                     </Button>
                  </Box>
               </Container>
            </Box>

            <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: 10 } }>
               <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
                  <Box sx={ { padding: "48px 14px" } }>
                     <Typography variant="h4" component="h1" gutterBottom>
                        CBD Flower Marketplace
                     </Typography>
                     <Typography variant="body1" gutterBottom>
                        Hempstok makes it easy to find high quality, in stock hemp flower from reputable growers, nationwide. Filter by type, effect, growth environment, new & restocked, and discounts.
                     </Typography>
                     <Typography variant="body1" gutterBottom> 
                        Our trusted partners rigorously test their hemp, ensuring compliance with the 2018 Farm Bill for federally legal industrial hemp.
                     </Typography>
                     <br />
                     <Typography variant="body1" gutterBottom>
                     <Link to="/signup">Create a free account</Link> to access exclusive discounts and enter drawings for free hemp. Your next favorite strain could be in your mailbox tomorrow!
                     </Typography>
                     <br />
                     <Typography variant="body1" gutterBottom>
                        Questions or comments? Email <a href="mailto:tim@hempstok.com">tim@hempstok.com</a>.
                     </Typography>
                  </Box>
               </Container>
            </Box>
         </MainContainer>
      </>
   )
}
