import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

/*
Use this, maybe?
// import { useTheme } from "@mui/material/styles"

const Item = styled( Paper )( ( { theme } ) => ( {
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   // padding: theme.spacing(1),
   color: theme.palette.text.secondary,
 } ) )
*/

export default function ListCard( { product } ) {
   const theme = useTheme()

   const styles = {
      productGrid: {
         display: 'grid',
         gridTemplateColumns: '28px minmax(100px, 1fr) 120px',
         gridTemplateRows: 'repeat( 2, auto )',
         marginTop: '21px',
         marginBottom: '21px',
         borderRadius: '7px',
         boxShadow: '0px 0px 5px 1px rgba(219,219,219,1)',
         paddingTop: '.3em',
         paddingBottom: '.3em',
         backgroundColor: '#ffffff',
         alignItems: 'center',
         gridGap: '.5em',
         gridRowGap: 0,
         [ theme.breakpoints.up('sm') ]: {
            gridTemplateColumns: '28px minmax(100px, 1fr) 120px 140px',
            gridTemplateRows: 'none',
         },
      },
      tab: {
         /* row-start / col-start / row-end / col-end */ 
         gridArea: '1 / 1 /  span 2 /  span 1',
         position: 'relative',
         borderRadius: '7px',
         margin: '.2em',
         height: '100%'
      },
      tabOrange: { backgroundColor: '#ff8427' },
      tabBlue: { backgroundColor: '#7798AB' },
      tabGreen: { backgroundColor: '#7baa45' },
      tabText: {
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
         color: '#ffffff',
         fontSize: '12px',
         fontWeight: 'bold',
         letterSpacing: '1px',
      },
      productDetails: {
         gridArea: '1 / 2 / span 2 / span 1',
         [ theme.breakpoints.up('sm') ]: {
            gridArea: '1 / 2 / span 1 / span 1',
         },
      },
      productThumbTitle: {
         display: 'flex',
         alignItems: 'center',
      },
      productThumb: {
         maxWidth: '75px',
         marginRight: '.5em',
      },
      productThumbWrapper: {
         display: 'flex',
         //flexWrap: 'wrap'
      },
      typeLabel: {
         backgroundColor: '#ff0000',
         color: '#fff',
         fontSize: '8px',
         fontWeight: 'bold',
         letterSpacing: '1px',
         TextAlign: 'center',
      },
      productMeta: {
         /* Needed to get text-overflow to work */
         minWidth: 0,
      },
      productMetaItem: {
         marginRight: '.5em',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
         whiteSpace: 'nowrap',
      }, 
      productTitle: {
         fontWeight: 'bold',
         fontSize: '16px',
      },
      productVendor: {
         fontSize: '15px',
      },
      productTag: {
         color: '#fff',
         fontSize: '10px',
         display: 'inline-block',
         padding: '2px 4px',
         fontWeight: 'bold',
         letterSpacing: '.5px',
         borderRadius: '4px',
      },
      productTagGreen: {
         backgroundColor: '#7baa45'
      },
       
      /* row-start col-start row-end col-end */ 
      productStats: {
         /* row-start / col-start / row-end / col-end */ 
         gridArea: '1 / 3 / span 1 / span 1'
      },
       
      productStatsGrid: {
         display: 'grid',
         gridTemplateColumns: '21px 10px 21px 1fr',
         fontSize: '21px',
         justifyItems: 'center',
      },
      statLabel: {
         fontSize: '8px',
         fontWeight: 'bold',
         letterSpacing: '1px',
         color: '#333',
         border: '1px solid #aaa',
      },
      productAttributes: {
         gridArea: '2 / 3 / span 1 / span 1'
      },
      productAttributeGrid: {
         display: 'grid',
         gridTemplateColumns: 'repeat( 4, 25px)',
         height: '25px',
         gridGap: '3px',
         [ theme.breakpoints.up('sm') ]: {
            gridTemplateColumns: 'repeat( 4, 30px)',
            height: '30px',
         },
      },
      attributeSquare: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         color: '#fff',
      },
      attribute1: { backgroundColor: '#ff8427' },
      attribute2: { backgroundColor: '#7baa45' },
      attribute3: { backgroundColor: '#40476d' },
      attribute4: { backgroundColor: '#333333' },

      /*
      vendorName: {
         [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
         },
      },
      */
   }

   // Tab
   let tabColor = ''
   let tabText = ''

   // Find the latest entry
   const entries = {
      'new': product.new_date ? product.new_date.seconds : 0,
      'restock': product.restock_date ? product.restock_date.seconds : 0,
      'sale': product.sale_date ? product.sale_date.seconds : 0
   }

   const dropType = Object.keys( entries ).reduce( ( a, b ) => entries[ a ] > entries[ b ] ? a : b )

   switch ( dropType ) {
      case 'new':
         tabColor = styles.tabBlue
         tabText = 'NEW'
         break
      case 'restock':
         tabColor = styles.tabOrange
         tabText = 'RESTOK'
         break
      case 'sale':
         tabColor = styles.tabGreen
         tabText = 'SALE'
         break
      default:
   }

   // <Typography component="h5" variant="h5" sx={ styles.vendorName }>

   return (
      <Box sx={ styles.productGrid }>
         <Box sx={ [ styles.tab, tabColor ] }>
            <Box sx={ styles.tabTextOuter }>
               <Box sx={ styles.tabText }>{ tabText }</Box>
            </Box>
         </Box>
         <Box sx={ styles.productDetails }>
            <Box sx={ styles.productThumbTitle }>
               <Box sx={ styles.productThumbWrapper }>
                  <img
                     style={ styles.productThumb }
                     src={ product.thumb_src }
                     alt=""
                  />
                  <Box sx={ styles.typeLabel }>
                     TYPE 1
                  </Box>
               </Box>
               <Box sx={ styles.productMeta }>
                  <Box sx={ [ styles.productMetaItem, styles.productTitle ] }>{ product.title }</Box>
                  <Box sx={ [ styles.productMetaItem, styles.productVendor ] }>{ product.vendorName }</Box>
                  { product.on_sale && <Box sx={ [ styles.productTag, styles.productTagGreen ] }>SALE</Box> }
                  <Stack direction="row" spacing={1}>
                     <Chip label="15% CBD" size="small" variant="outlined" />
                     <Chip label="5% THCA" size="small" variant="outlined" />
                  </Stack>
               </Box>
            </Box>
         </Box>
         <Box sx={ styles.productStats }>
            <Box sx={ styles.productStatsGrid }>
               <Box sx={ styles.r1 }>1</Box>
               <div>:</div>
               <Box sx={ styles.r2 }>5</Box>
               <div>25%</div>
               <Box sx={ styles.statLabel }>THC</Box>
               <div />
               <Box sx={ styles.statLabel }>CBD</Box>
               <Box sx={ styles.statLabel }>TOTAL</Box>
            </Box>
         </Box>
         <div>
            <Box sx={ styles.productAttributes }>
               <Box sx={ styles.productAttributeGrid }>
                  <Box sx={ [ styles.attributeSquare,  styles.attribute1 ] }>T1</Box>
                  <Box sx={ [ styles.attributeSquare, styles.attribute2 ] }>T2</Box>
                  <Box sx={ [ styles.attributeSquare, styles.attribute3 ] }>T3</Box>
                  <Box sx={ [ styles.attributeSquare, styles.attribute4 ] }>T4</Box>
               </Box>
            </Box>
         </div>
      </Box>
   )

   /*
   return (
      <Box sx={ styles.productGrid }>
         <Box sx={ [ styles.tab, styles.tabOrange ] }>
            <Box sx={ styles.tabTextOuter }>
               <Box sx={ styles.tabText }>RESTOK</Box>
            </Box>
         </Box>
         <Box sx={ styles.productDetails }>
            <Box sx={ styles.productThumbTitle }>
               <Box>
                  <img
                     style={ styles.productThumb }
                     src={ product.thumb_src }
                     alt=""
                  />
               </Box>
               <Box sx={ styles.productMeta }>
                  <Box sx={ [ styles.productMetaItem, styles.productTitle ] }>{ product.title }</Box>
                  <Box sx={ [ styles.productMetaItem, styles.productVendor ] }>{ product.vendorName }</Box>
               </Box>
            </Box>
         </Box>
         <Box sx={ styles.productStats }>
            <Box sx={ styles.productStatsGrid }>
               <Box sx={ styles.r1 }>1</Box>
               <div>:</div>
               <Box sx={ styles.r2 }>5</Box>
               <div>25%</div>
               <Box sx={ styles.statLabel }>THC</Box>
               <div />
               <Box sx={ styles.statLabel }>CBD</Box>
               <Box sx={ styles.statLabel }>TOTAL</Box>
            </Box>
         </Box>
         <div>
            <Box sx={ styles.productAttributes }>
               <Box sx={ styles.productAttributeGrid }>
                  <Box sx={ [ styles.attributeSquare,  styles.attribute1 ] }>T1</Box>
                  <Box sx={ [ styles.attributeSquare, styles.attribute2 ] }>T2</Box>
                  <Box sx={ [ styles.attributeSquare, styles.attribute3 ] }>T3</Box>
                  <Box sx={ [ styles.attributeSquare, styles.attribute4 ] }>T4</Box>
               </Box>
            </Box>
         </div>
      </Box>
   )
   */
}