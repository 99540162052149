import { Box, Container, FormControl, IconButton, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import ProductCard from '../../ProductCard'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useDebouncedCallback } from 'use-debounce'
import { useState, useEffect } from 'react'

const StrainsList = ({
   handleModalClickOpen,
   handleFriendsClickOpen,
   userData,
   handleFav,
   filteredProducts,
   filters,
}) => {

   // Come back to. I don't need value anymore
   // but taking it out causes it not tow wrok anymore
   const [ value, setValue ] = useState( filters.text )

   const debounced = useDebouncedCallback(
      ( value ) => {
         setValue( value )
      },
      1000
   )
   
   /*
   // I don't think I'm using this correctly
   const debounced = useDebouncedCallback(
      () => {},
      1000
   )
      */

   const [ filtered, setFiltered ] = useState(
      // Sorts the products alphabetically by title
      filteredProducts.sort( ( a, b ) => a.title.localeCompare( b.title ) )
   )

   useEffect( () => {
      const searchBoxValue = document.getElementById('searchBox').value
      
      const fProducts = filteredProducts.filter( ( product ) => {

         if ( product.title.toLowerCase().includes( searchBoxValue.toLowerCase() ) ) {
            return true
         }

         return false
      } )

      setFiltered( fProducts )

   }, [ filteredProducts ] )

   const handleInputChange = ( event ) => {

      if ( event.target.value === '' ) {
         setFiltered( filteredProducts )
      } else {
         // Accounts for users typing multiple characters in quick succession
         debounced( () => {
            const fProducts = filteredProducts.filter( ( product ) => {

               if ( product.title.toLowerCase().includes( event.target.value.toLowerCase() ) ) {
                  return true
               }

               return false
            } )

            setFiltered( fProducts )
         } )
      }
   }

   const targetProducts = filtered.length ? filtered : filteredProducts

   return (
      <Box sx={ { backgroundColor: "grey.main",  } }>
         <Container
            maxWidth="xl"
            sx={ {
               paddingLeft: { xs: 0, sm: 'inherit' },
               paddingRight: { xs: 0, sm: 'inherit' },
            } }
         >
            <Box
               sx={ {
                  padding: '2rem 1rem',
                  textAlign: 'center',
                  backgroundColor: '#e3e4dd',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
               } }
            >
               <FormControl
                  variant="outlined"
                  sx={{
                     width: { xs: '100%', sm: '48ch', md: '70ch' },
                  }}
               >
                  <TextField
                     id="searchBox"
                     size="large"
                     sx={ { backgroundColor: '#ffffff' } }
                     InputProps={ {
                        startAdornment:
                           <IconButton>
                              <SearchIcon/>
                           </IconButton>,
                        endAdornment:
                        <IconButton
                           onClick={ () => {
                              setFiltered( filteredProducts )
                              document.getElementById('searchBox').value=''
                           } }
                        >
                           <CloseIcon/>
                        </IconButton>,
                     } }
                     onChange = { handleInputChange }
                     placeholder='Search strains'
                  />
                  
               </FormControl>
               { 
               
               filtered.length && filtered.length !== filteredProducts.length ? (
                  <Typography
                     variant='subtitle2'
                     marginLeft='1rem'
                     fontSize='1.3rem'
                     color='secondary.main'
                     whiteSpace="nowrap"
                     lineHeight='1.1'
                  >
                     <Box>
                        <Box component="span" sx={ { fontWeight: 'bold' } }>
                           { filtered.length }
                        </Box>
                        &nbsp;of&nbsp;
                        <Box component="span" sx={ { fontWeight: 'bold' } }>
                           { filteredProducts.length }
                        </Box>
                     </Box>
                     strains
                  </Typography>
               ) : (
                  <Typography
                     variant='subtitle2'
                     marginLeft='1rem'
                     fontSize='1.3rem'
                     color='secondary.main'
                     whiteSpace='nowrap'
                     lineHeight='1.1'
                  >
                     <Box sx={ { fontWeight: 'bold', fontSize: 'larger' } }>
                        { filteredProducts.length }
                     </Box>
                     strains
                  </Typography>
               )
            }
            </Box>
            <Box sx={ { backgroundColor: '#e3e4dd', padding: '14px' } }>
               <Grid container spacing={ 3 }>
               {
                  targetProducts.map( ( product, idx ) => {
                     return (
                        <Grid xs={ 6 } sm={ 3 } lg={ 2 } xl={ 2 } key={ product.id }>
                           <ProductCard
                              product={ product }
                              vendorSlug={ product.vendorSlug }
                              handleModalClickOpen={ handleModalClickOpen }
                              handleFriendsClickOpen={ handleFriendsClickOpen}
                              vendorName={ product.vendorName }
                              userData={ userData }
                              handleFav={ handleFav }
                           />
                        </Grid>
                  ) } )
               }
               </Grid>
            </Box>
         </Container>
      </Box>
   )
}

export default StrainsList
