import { Box } from '@mui/material'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Tooltip from '@mui/material/Tooltip'

import { usePageContext } from '../hooks/usePageContext'

import t1Icon from './VendorInventory/info-icons/t1.png'
import t2Icon from './VendorInventory/info-icons/t2.png'
import t3Icon from './VendorInventory/info-icons/t3.png'
import t4Icon from './VendorInventory/info-icons/t4.png'
import outdoorIcon from './VendorInventory/info-icons/outdoor.svg'
import indoorIcon from './VendorInventory/info-icons/indoor.svg'
import sativaIcon from './VendorInventory/info-icons/sativa.svg'
import indicaIcon from './VendorInventory/info-icons/indica.svg'
import hybridIcon from './VendorInventory/info-icons/hybrid.svg'

import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../firebase/config';
import { useAuthContext } from '../hooks/useAuthContext';

export default function RenderThumbnail( {
   favsArray = [],
   product,
   handleFriendsClickOpen,
   handleModalClickOpen,
} ) {

   const { coupons } = usePageContext()
   
   const { user } = useAuthContext()
   
   // Current timestamp in seconds minus 3 days
   const offset_timestamp = ( Date.now() / 1000 ) - ( 60 * 60 * 24 * 3 )
   
   const styles = {
      thumbContainer: {
         position: 'relative',
         // borderBottom: '1 px solid #e91e63',
         '::after': {
            content: '""',
            display: 'block',
            paddingBottom: '100%'
         },
      },
      thumb: {
         position: 'absolute',
         width: '100%',
         height: '100%',
         objectFit: 'cover',
         objectPosition: '50% 50%',
         backgroundColor: '#eeeeee'
      },
      favIconContainer: {
         position: 'absolute',
         top: '5px',
         right: '5px',
         textAlign: 'right',
      },
      infoIconContainer: {
         position: 'absolute',
         bottom: 0,
         right: 0,
         width: '26px',
      },
      infoIcon: {
         width: '21px',
      },
      onSaleBorder: {
         filter: 'drop-shadow(5px 5px 0px #7baa45)'
      },
      // https://www.cssportal.com/css-ribbon-generator/
      ribbon: {
         position: 'absolute',
         left: '-5px; top: -5px',
         zIndex: 1,
         overflow: 'hidden',
         width: '75px',
         height: '75px',
         textAlign: 'right',
      },
      ribbonSpan: {
         fontSize: '10px',
         fontWeight: 'bold',
         color: '#FFF',
         textTransform: 'uppercase',
         textAlign: 'center',
         lineHeight: '20px',
         transform: 'rotate(-45deg)',
         width: '100px',
         display: 'block',
         boxShadow: '0 3px 10px -5px rgba(0, 0, 0, 1)',
         position: 'absolute',
         top: '19px; left: -21px',
         '&::before': {
            content: '""',
            position: 'absolute; left: 0px; top: 100%',
            zIndex: -1,
            borderRight: '3px solid transparent',
            borderBottom: '3px solid transparent',
         },
         '&::after': {
            content: '""',
            position: 'absolute; right: 0px; top: 100%',
            zIndex: -1,
            borderLeft: '3px solid transparent',
            borderBottom: '3px solid transparent',
         }
      },
      'ribbonSpanGreen': {
         background: 'linear-gradient(#7BAA45 0%, #8ABF4E 100%)',
         '&::before': {
            borderLeft: '3px solid #8ABF4E',
            borderTop: '3px solid #8ABF4E',
         },
         '&::after': {
            borderRight: '3px solid #8ABF4E',
            borderTop: '3px solid #8ABF4E',
         }
      },
      'ribbonSpanOrange': {
         background: 'linear-gradient(#FF8427 0%, #EB7924 100%)',
         '&::before': {
            borderLeft: '3px solid #EB7924',
            borderTop: '3px solid #EB7924',
         },
         '&::after': {
            borderRight: '3px solid #EB7924',
            borderTop: '3px solid #EB7924',
         }
      },
      'ribbonSpanGray': {
         background: 'linear-gradient(#999999 0%, #878787 100%)',
         '&::before': {
            borderLeft: '3px solid #878787',
            borderTop: '3px solid #878787',
         },
         '&::after': {
            borderRight: '3px solid #878787',
            borderTop: '3px solid #878787',
         }
      },
   }

   const renderRibbon = () => {
      if ( "in_stock" in product && ! product.in_stock ) {
         return (
            <Box
               sx={ styles.ribbon }
               onClick={ () => { 
                  if ( handleModalClickOpen ) {
                     handleModalClickOpen( product )
                  }
                  else {
                     return false
                  }
               } }
            >
               <Box sx={ [ styles.ribbonSpan, styles.ribbonSpanGray ] }>ALL GONE</Box>
            </Box>
         )
      }      
      
      if ( "new_date" in product && product.new_date ) {

         if ( product.new_date.seconds > offset_timestamp ) {
            return (
               <Box
                  sx={ styles.ribbon }
                  onClick={ () => { 
                     if ( handleModalClickOpen ) {
                        handleModalClickOpen( product )
                     }
                     else {
                        return false
                     }
                  } }
               >
                  <Box sx={ [ styles.ribbonSpan, styles.ribbonSpanGreen ] }>NEW</Box>
               </Box>
            )
         }
      }

      if ( "restock_date" in product && product.restock_date ) {
         if ( product.restock_date.seconds > offset_timestamp ) {
            return (
               <Box sx={ styles.ribbon }>
                  <Box sx={ [ styles.ribbonSpan, styles.ribbonSpanOrange ] }>RESTOCK</Box>
               </Box>
            )
         }
      }
   }

   const renderInfoIcons = () => {
      let typeIcon = ''
      let enviroIcon = ''
      let effectIcon = ''

      switch ( product.hemp_type ) {
         case 1:
            typeIcon = t1Icon
            break
         case 2:
            typeIcon = t2Icon
            break
         case 3:
            typeIcon = t3Icon
            break
         case 4:
            typeIcon = t4Icon
            break
         default:
            typeIcon = ""
      }

      if ( product.enviro ) {
         switch ( product.enviro ) {
            case "indoor":
               enviroIcon = indoorIcon
               break
            case "outdoor":
               enviroIcon = outdoorIcon
               break
            default:
               enviroIcon = ""
         }
      }

      if ( product.effect ) {
         switch ( product.effect ) {
            case "sativa":
            case "sativa-leaning":
               effectIcon = sativaIcon
               break
            case "indica":
            case "indica-leaning":
               effectIcon = indicaIcon
               break
            case "hybrid":
               effectIcon = hybridIcon
               break
            default:
               effectIcon = ""
         }
      }

      return (
         <Box sx={ styles.infoIconContainer }>
            <Tooltip title={ `Type ${ product.hemp_type }` } arrow>
               <img src={ typeIcon } alt={`Type ${ product.hemp_type }`} style={ styles.infoIcon }/>
            </Tooltip>
            { effectIcon && <Tooltip title={ product.effect } arrow><img src={ effectIcon } alt={ product.effect } style={ styles.infoIcon }/></Tooltip> }
            { /* enviroIcon && <img src={ enviroIcon } alt={ product.enviro } style={ styles.infoIcon }/> */}
         </Box>
      )
   }

   const handleFav = ( product ) => {
      let favs = []
      
      if (  favsArray && favsArray.length ) {
         favs = favsArray
         
         if ( favs && ! favs.includes( product.id ) ) {
            favs.push( product.id )
         } else {
            favs.splice(favs.indexOf( product.id ), 1);
         }
      } else {
         favs.push( product.id )
      }
      
      try {
         updateDoc( doc ( db, 'users', user.uid ), { favs: favs } )
      } 
      catch ( error ) {
         console.log('error', error)
      }
   }

   const renderFavIcon = () => {
      if ( user ) {
         return (
            <Box sx={ styles.favIconContainer }>
               {
                  ( favsArray && favsArray.includes( product.id ))
                  ? (
                     <Tooltip title="Remove" arrow>
                        <FavoriteIcon
                           color="pink"
                           sx={ { fontSize: 28 } }
                           onClick={ () => handleFav( product ) }
                        />
                     </Tooltip>
                  )
                  : (
                     <Tooltip title="Add" arrow>
                        <FavoriteBorderOutlinedIcon
                           color="pink"
                           sx={ { fontSize: 28 } }
                           onClick={ () => handleFav( product ) }
                        />
                     </Tooltip>
                  )
               }
            </Box>
         )
      }
      
      return (
         <Box sx={ styles.favIconContainer }>
            <Tooltip title="Login to Add Favorite" arrow>
               <FavoriteBorderOutlinedIcon
                  color="pink"
                  sx={ { fontSize: 28 } }
                  onClick={ handleFriendsClickOpen }
               />
            </Tooltip>
         </Box>
      )
   }

   const cDataVendor = 'coupons[ product.vendorSlug ]'
   let coupon = null

   if ( cDataVendor && cDataVendor.couponCode ) {
      
      // ?
      coupon = {
         code: cDataVendor.couponCode,
         discount: cDataVendor.discount,
      }
   }

   /*
      // I think this is missing from the thumbnail
      // Maybe something like this fixes it    
         { ( coupon || product.on_sale ) && (s
            <Box
               sx={ { ...styles.couponBox, backgroundColor: product.on_sale ? '#7baa45' : '#3d5125' } }
               onClick={ () => { 
                  if ( handleModalClickOpen ) {
                     handleModalClickOpen( product )
                  }
                  else {
                     return false
                  }
               } }
            >
               <LocalOfferIcon sx={ styles.couponIcon } /> 
               <Typography sx={ styles.couponText }>
                  { product.on_sale ? 'ON SALE!' : `SHOP ${ coupon.discount }` }
               </Typography>
            </Box>
         ) }
   */

   return (
      <Box
         sx={ {
            ...styles.thumbContainer,
            // ...product.on_sale ? styles.onSaleBorder : {}
         } }
      >
         { renderRibbon() }
         <img
            onClick={ () => { 
               if ( handleModalClickOpen ) {
                  handleModalClickOpen( product )
               }
               else {
                  return false
               }
            } }
            src={ product.thumb_src }
            alt={ product.title }
            style={ styles.thumb }
            className="thumbContainer"
         />
         { /* <img src="https://placekitten.com/g/300/200" alt={product.title} width="100%" /> */ }
         { renderInfoIcons() }
         { renderFavIcon() }
      </Box>
   )
}
