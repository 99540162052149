import { useParams } from 'react-router-dom'
import VendorInventory from '../../components/VendorInventory/VendorInventory'
import { Helmet } from "react-helmet"

const FarmProfile = () => {
   const { slug } = useParams()
   return (
      <>
         <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Shop High-CBD Flower Strains, Delivered Farm-Direct to Your Door" />
            <title>Dick</title>
            <link rel="canonical" href="https://hempstok.com" />
         </Helmet>
         <VendorInventory
            mode="farmProfile"
            slug={ slug }
         />
      </>
   )
}

export default FarmProfile
