import { Box, Typography } from '@mui/material'
import VendorLogoBox from './VendorLogoBox'
import Button from '@mui/material/Button'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import { useTheme } from "@mui/material/styles"

export default function VendorLogoGrid( { vendors, visibleVendors = [], setVisibleVendors, products } ) {
   const theme = useTheme()
   
   const styles = {
      vendorLogoBox: {
         padding: 2,
         borderBottom: `1px solid #7baa45`,
         cursor: 'pointer',
      },
      activeVendor: {
         backgroundColor: '#e3e4dd'
      },
      vendorLogoContainer: {
         position: 'sticky',
         top: '150px',
         overflow: 'hidden',
         "::-webkit-scrollbar": {
            display: 'none'
         },
         scrollbarWidth: 'none',
      },
      moreButton: {
         paddingTop: 2,
         paddingBottom: 2,
         height: '70px',
         textAlign: "center",
         [ theme.breakpoints.down('md') ]: {
            display: 'none'
         },
      },
      moreIcon: {
         textAlign: "center",
         marginBottom: 2,
         [ theme.breakpoints.up('md') ]: {
            display: 'none'
         },
      },
      moreIconButton: {
         [ theme.breakpoints.down('sm') ]: {
            minWidth: 'auto',
            paddingLeft: 1,
            paddingRight: 1,
         },
      }
   }

   // visibleVendors.sort()
   let topLogos = []
   let activeLogos = []
   let bottomLogos = []

   if ( vendors ) {
      topLogos = vendors.filter( ( vendor, index ) => Math.min(...visibleVendors) > index )
      activeLogos = vendors.filter( ( vendor, index ) => visibleVendors.includes( index ) )
      bottomLogos = vendors.filter( ( vendor, index ) => Math.max(...visibleVendors) < index )
   }

   const scrollToVendor = ( id ) => {
      document.getElementById( id ).scrollIntoView()
      
      // None of this fucking shit works. This has been the biggest pain in the ass.
      // The pages scrolls too fast for instersect observer to update correctly.
      setVisibleVendors( [] )
      topLogos = []
      activeLogos = []
      bottomLogos = []
   }

   return (
      <>
         <Box sx={ styles.vendorLogoContainer }>
            <Box sx={ styles.moreButton }>
               { topLogos.length > 0 && (
                  <Button
                     variant="contained"
                     color="darkBlue"
                     endIcon={ <KeyboardDoubleArrowUpIcon /> }
                     onClick={ () => scrollToVendor( `${ vendors[0].slug }` ) }
                     sx={ { animation: 'fadeIn 1s' }}
                  >
                     { `${ topLogos.length } More` }
                  </Button>
               ) }
            </Box>
            <Box sx={ styles.moreIcon }>
               { topLogos.length > 0 && (
                  <Box sx={ { animation: 'fadeIn 1s' }}>
                     <Button
                        variant="contained"
                        color="darkBlue"
                        href={ `#${ vendors[0].slug }` }
                        sx={ styles.moreIconButton }
                     >
                        <KeyboardDoubleArrowUpIcon />
                     </Button>
                     <Typography variant="subtitle2">
                        { `${ topLogos.length } More` }
                     </Typography>
                  </Box>
               ) }
            </Box>
            <Box>
               {
                  activeLogos && activeLogos.map( ( vendor, index ) => (
                     <VendorLogoBox
                        slug ={ vendor.slug }
                        logoURL = { vendor.logoURL }
                        iconURL = { vendor.iconURL }
                        inView = { true }
                        key={ vendor.id }
                        scrollToVendor = { scrollToVendor }
                        vendorName = { vendor.title }
                     />
                  ) )
               }
            </Box>
            <Box>
               {
                  bottomLogos && bottomLogos.map( ( vendor, index ) => (
                     <VendorLogoBox
                        slug ={ vendor.slug }
                        logoURL = { vendor.logoURL }
                        iconURL = { vendor.iconURL }
                        inView = { false }
                        key={ vendor.id }
                        scrollToVendor = { scrollToVendor }
                        vendorName = { vendor.title }
                     />
                  ) )
               }
            </Box>
         </Box>
      </>
   )
}
