import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Unstable_Grid2'
import Container from '@mui/material/Container'
import { Paper, Box, IconButton } from '@mui/material'
import { Interweave } from 'interweave'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useAuthContext } from '../hooks/useAuthContext'

import LocalOfferIcon from '@mui/icons-material/LocalOffer'

import { Typography, Button } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import RenderThumbnail from './RenderThumbnail'

import { useState } from "react"

import InlineAccountAccess from './InlineAccountAccess'
import HempStats from './HempStats'

import { usePageContext } from '../hooks/usePageContext'

export default function ProductModal( {
   open,
   handleClose,
   favsArray,
   handleFriendsClickOpen,
   product,
} ) {

   const { channel, coupons } = usePageContext()
   const { user } = useAuthContext()
   const [ showForm, setShowForm ] = useState( null )

   const isMobile = useMediaQuery( '(max-width:600px)' )

   const buildURL = ( url, cData ) => {

      // Third-party affiliate link
      if ( cData.baseURL) {
         return `${ cData.baseURL }${ encodeURIComponent( url ) }`
      }
      
      let linkExtension = "utm_source=hempstok&ref=hempstok"
      
      if ( channel ) {
         switch( channel ) {
            case 'shabazzstok':
               linkExtension = "utm_source=shabazzstok&ref=shabazzstok"
               break
            default:
               linkExtension = "utm_source=hempstok&ref=hempstok"
         }
      }
      
      if ( cData.linkExt ) {
         linkExtension = `${ cData.linkExt }&${ linkExtension }`
      }
      
      if ( url.includes('?') ) {
         return `${ url }&${ linkExtension }` 
      }

      return `${ url }?${ linkExtension }` 
   }

   const styles = {
      dialogTitle: {
         backgroundColor: '#7baa45',
         color: '#ffffff',
         textAlign: 'center',
      },
      closeBtn: {
         position: 'absolute',
         right: 0,
         top: 0,
         color: "#ffffff",
      },
      dialogActions: {
         borderTop: '5px solid #e91e63',
         backgroundColor: '#e3e4dd',
         padding: 2,
         display: 'block'
      },
      onSaleBorder: {
         border: '4px solid #7baa45'
      },
      onSaleTitle: {
         backgroundColor: '#7baa45',
         color: '#ffffff'
      },
      offerContainer: {
         marginBottom: 2,
         paddingTop: .5,
         paddingBottom: .5
      },
      offerBorder: {
         border: '4px dashed #7baa45',
         borderRadius: '7px',
         margin: '1rem'
      },
      offerType: {
         fontSize: '1.5rem',
         fontWeight: 'bold',
         lineHeight: '1.5rem',
         textAlign: 'center'
      },
      offerTitle: {
         fontWeight: '600',
         fontSize: '1.1rem',
      },
      termsList: {
         listStyle: 'initial',
         margin: 'initial',
         padding: '0 0 0 40px',
      },
      affiliateDisclosure: {
         fontSize: '1rem',
         marginTop: '2rem'
      }
   }

   const handleShowFormClick = ( open ) => {
      setShowForm( open )
   }

   const handleCodeCopyClick = ( couponCode ) => {
      
      // GA4 Event
      window.dataLayer.push( {
         event: 'vendor_click_through',
         uid: user ? user.uid : '',
         email: user ? maskEmail( user.email ) : '',
         vendor_slug: product.vendorSlug,
         vendor_name: product.vendorName,
         product_id: product.id,
         product_title: product.title,
         coupon_code: couponCode
      } )

      window.open( buildURL( product.profile_url, channelData ) )
   }

   const maskEmail = ( email ) => {
      if ( ! email ) return ''
      return email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c)
   }

   let channelData = {}
   let actionContent = ''
   
   if ( product ) {
      channelData = coupons[ product.vendorSlug ]

      if ( channelData && channelData.couponCode ) {
         actionContent = (
            <>
               <Box textAlign={ 'center' }>
                  { user && (
                     <>
                     <Typography
                        variant='subtitle2'
                        gutterBottom
                     >
                        Enter "{ channelData.couponCode }" at checkout for { channelData.discount }
                     </Typography>
                     <Button
                        variant="contained"
                        color="primary"
                        startIcon={ <LocalOfferIcon /> }
                        sx={ { fontWeight: 'bold' } }
                        size={ isMobile ? 'small' : 'large' }

                        onClick={ async () => {
                           await navigator.clipboard.writeText( channelData.couponCode )
                           handleCodeCopyClick( channelData.couponCode )
                        } }
                     >
                        Copy Coupon & Shop
                     </Button>
                     <Button
                        variant="contained"
                        color="darkBlue"
                        onClick={ handleClose }
                        size={ isMobile ? 'small' : 'large' }
                        sx={ {
                           // color: 'blue',
                           fontWeight: 'bold',
                           marginLeft: 2
                        } }
                     >
                        Close
                     </Button>
                     </>
                  ) }
                  { !user && (
                     <>
                     { showForm && <InlineAccountAccess setShowForm={ setShowForm } /> }
                     { !showForm && (
                     <Box>
                        <Button
                           variant="contained"
                           color="primary"
                           sx={ { marginRight: 1, fontWeight: 'bold' } }
                           size={ isMobile ? 'small' : 'large' }
                           onClick={ () => handleShowFormClick( true ) }
                        >
                           Use Discount
                        </Button>
                        <Button
                           variant="text"
                           color="secondary"
                           sx={ { marginRight: 1, fontWeight: 'bold' } }
                           size={ isMobile ? 'small' : 'large' }
                           onClick={ () => {
                              // GA4 Event
                              window.dataLayer.push( {
                                 event: 'vendor_click_through',
                                 uid: user ? user.uid : '',
                                 email: user ? maskEmail( user.email ): '',
                                 vendor_slug: product.vendorSlug,
                                 vendor_name: product.vendorName,
                                 product_id: product.id,
                                 product_title: product.title,
                                 coupon_code: ''
                              } )
                              
                              window.open( buildURL( product.profile_url, channelData ) )
                           } }
                        >
                           Shop Direct
                        </Button>
                        <IconButton
                           onClick={ handleClose }
                           //sx={ styles.closeBtn }
                           size={ isMobile ? 'small' : 'large' }
                        >
                           <CancelIcon />
                        </IconButton>
                     </Box>
                     ) }
                     </>
                  ) }
               </Box>
            </>
         )
      } else {
         actionContent = (
            <Box textAlign={ 'center' }>
               <Button
                  variant="contained"
                  color="primary"
                  sx={ { fontWeight: "bold" } }
                  onClick={ () => {
                     // GA4 Event
                     window.dataLayer.push( {
                        event: 'vendor_click_through',
                        uid: user ? user.uid : '',
                        email: user ? maskEmail( user.email ): '',
                        vendor_slug: product.vendorSlug,
                        vendor_name: product.vendorName,
                        product_id: product.id,
                        product_title: product.title,
                        coupon_code: ''
                     } )

                     window.open( buildURL( product.profile_url, ( channelData ? channelData : {} ) ) )
                     //handleClose()
                     }
                  }
               >
                  Shop
               </Button>
               <Button
                  variant="contained"
                  color="darkBlue"
                  onClick={ handleClose }
                  sx={ {
                     // color: 'blue',
                     fontWeight: 'bold',
                     marginLeft: 2
                  } }
               >
                  Close
               </Button>

            </Box>
         )
      }
   }

   const formatTitleURL = ( title ) => {
      let formattedTitle = ''

      if ( title ) {
         formattedTitle = title.replace(/\W+/g, '-').toLowerCase()
      }

      return formattedTitle
   }

   return (
      <Dialog
         open={ open }
         onClose={ handleClose }
         fullScreen
         aria-labelledby="productModal"
         aria-describedby="productModalDescription"
      >
         { /*
            <Container maxWidth="md" sx={ { position: 'relative' } }>
               { `"${ product.title }" by ${ product.vendorName }` }
               <IconButton
                  onClick={ handleClose }
                  sx={ styles.closeBtn }
               >
                  <CancelIcon />
               </IconButton>
            </Container>
         */}

         <DialogTitle id="productModal" sx={ styles.dialogTitle }>
            <Container maxWidth="md" sx={ { position: 'relative' } }>
               { `"${ product.title }" by ${ product.vendorName }` }
               <IconButton
                  onClick={ handleClose }
                  sx={ styles.closeBtn }
               >
                  <CancelIcon />
               </IconButton>
            </Container>      
         </DialogTitle>

         <DialogContent sx={ { paddingLeft: '0', paddingRight: 0 } }>
            <DialogContentText
               component='div'
               id="productModalDescription"
               sx={ {
                  marginTop: '2rem',
                  //height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  //maxHeight: 'calc(100vh - 100px)',
               } }>
                  <Container maxWidth="md">
                     <Grid container spacing={3} alignItems="center">
                        <Grid xs={ 12 } sm={ 4 }>
                           <Paper
                              elevation={ 4 }
                              style={ styles.productContainer }
                              sx={ product.on_sale ? styles.onSaleBorder : {} }
                           >
                              <RenderThumbnail
                                 favsArray = { favsArray }
                                 product = { product }
                                 handleFriendsClickOpen = { handleFriendsClickOpen }
                              />
                           </Paper>
                           { /*
                           <Button href={ `/v/${ product.vendorSlug }/${ product.id }/${ formatTitleURL( product.title ) }` }>
                              Share
                           </Button>
                           */ }
                        </Grid>
                        <Grid xs={ 12 } sm={ 8 }>
                           <HempStats product={ product } />
                        </Grid>
                     </Grid>
                     { product.description && (
                        <Typography variant='body1' component="div" marginTop={ 2 }>
                           { <Interweave content={ product.description } /> }
                        </Typography>
                     ) }
                  </Container>
            </DialogContentText>
         </DialogContent>
         <DialogActions sx={ styles.dialogActions }>
            { actionContent }
         </DialogActions>
      </Dialog>      
   )
}