const shabazzCoupons = {
   // Hempstok code
   akirabotanicals: {
      // couponCode: 'shabazzstok', //hempstok
      couponCode: 'SHABAZZ24', // Shabazz
      discount: '10% Off',
      linkExt: ''
   },
   // Shabazz's discount code
   atlantiscannabis: {
      couponCode: 'SHABAZZ',
      discount: '10% Off',
      linkExt: ''
   },
   // Shabazz's coupon code
   beleafer: {
      couponCode: 'SHABAZZ',
      discount: '7.5% Off',
      linkExt: ''
   },
   cascadiablooms: {
      couponCode: '',
      discount: '',
      linkExt: ''
   },
   // Clutch wouldn't give me a discount code for Shabazz
   // Shabazz's coupone code
   clutchcityfarms: { // Uppromote affiliate
      couponCode: 'SHABAZZ',
      discount: '10% Off',
      linkExt: ''
   },
   // Hempstok code
   dieselhemp: { // Some other affiliate
      couponCode: 'shabazzstok', // hempstok. Shabazz doesn't have a code.
      discount: '10% Off',
      linkExt: ''
   },
   eighthorses: {
      couponCode: '',
      discount: '',
      linkExt: ''
   },
   // Hempstok Code
   flowgardens: {
      couponCode: 'Shabazz10', // Shabazz doesn't have a code.
      discount: '10% Off',
      linkExt: ''
   },
   // Shabazz's code
   hcf: {
      couponCode: 'WELUVSHABAZZ',
      discount: '5% Off',
      linkExt: 'affid=0OSFIK00YY'
   },
   horncreek: {
      couponCode: '',
      discount: '',
      linkExt: ''
   },
   // Shabazz coupon
   lostoakfarms: {
      couponCode: 'Shabazz',
      discount: '35% Off',
      linkExt: ''
   },
   naturalnuggs: { // hempstok
      // couponCode: 'shabazzstok',
      couponCode: 'SHABAZZ',
      discount: '10% Off',
      linkExt: ''
   },
   neverwinterbotanicals: { // hempstok
      // couponCode: 'shabazzstok',
      couponCode: 'SHABAZZ',
      discount: '10% Off',
      linkExt: ''
   },
   stoneybranch: {
      // couponCode: 'shabazzstok', // hempstok
      couponCode: 'Shabazz20', // Shabazz
      discount: '20% Off',
      linkExt: 'utm_source=shabazzstok'
   }
}

export default shabazzCoupons
