
import { Box, Paper, Typography, Link } from '@mui/material'
import StokCertified from './hempstok-certified.png'

export default function VendorCard( { vendorData } ) {
   return (
      <Box
         sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
         }}
      >
         <Box>
            <Box
               component="img"
               src={ vendorData.logoURL }
               alt={ vendorData.title }
               p={ 1 }
               sx={ { borderBottom: '1px solid #e91e63', width: '100%' } }
            />
            { vendorData.location && (
               <Typography variant='body1' component='div' sx={ { textAlign: 'center' } }>
                  { vendorData.location }
               </Typography>
            ) }
            { vendorData.accountType === 'certified' && (
               <Box sx={ {
                  backgroundColor: '#e91e63',
                  color: '#fff',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '1em',
               } }
               >
                  STOK CERTIFIED
               </Box>
            )}
         </Box>
      </Box>
) }
