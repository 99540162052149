import { Box, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Button from '@mui/material/Button'
import ProductCard from '../../ProductCard'
import VendorCard from '../../VendorCard'
import PlaceIcon from '@mui/icons-material/Place';
import { forwardRef } from 'react'
import { usePageContext } from '../../../hooks/usePageContext'

export default forwardRef( function Scroller( {
   userData,
   handleFav,
   handleModalClickOpen,
   handleFriendsClickOpen,
   filteredProducts = [],
   vendorList,
   scrollToTopButton = false,
   headerTitle = '',
   id = '',
   headerBackgroundColor,

   vendorLogo = true,
   vendorCardSlug = '',
}, ref ) {

   const { channel, toolbarHeight, scrollMarginTop } = usePageContext()

   const scrollerData = {
      headerTitle: headerTitle,
      headerBackgroundColor: headerBackgroundColor,
      scrollToTopButton: scrollToTopButton,
      headlineImage: '',
      headlineContent: ''
   }

   if ( vendorList.length && vendorCardSlug ) {
      const vendorData = vendorList.find( vendor => vendor.slug === vendorCardSlug )
      scrollerData.headlineImage = vendorData.logoURL
      scrollerData.headlineContent = vendorData.location
   }

   const styles = {
      scrollerWrapper: {
         padding: '21px 0 21px 0',
         display: 'grid',
      
         // Setting up main structure
         gridTemplateColumns: '14px 1fr 14px',
      
         /* Any direct children of .scroller-wrapper will be ‘containerized’ with a 20px gap
         on both ends keeping the content off the edges. It's kinda like centering a container div.
         I need to use these selectors more
         */

         // I would like to have the productCard extend to the edge of the scroller. Currrently just scrolls inside/behind.
         // Worked on it for a bit but couldn't figure it out. Kicked my ass. 7/3/24

         '& > *': {
            gridColumn: '2 / -2',
         },
         '& > .full': {
            gridColumn: '1 / -1',
         },
         background: '#e3e4dd'
      },
      scroller: {
         display: 'grid',
         gridGap: '21px',
      
         gridAutoFlow: 'column',
      
         overflowX: 'scroll',

         /*
         '&::before': {
            content: "''",
            width: '10px',
         },
         */
      },
      noScrollbar: {
         scrollbarWidth: 'none',
         marginBottom: 0,
         paddingBottom: 0,
         '::-webkit-scrollbar': {
            display: 'none'
         }
      },
   }

   const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

   let numRowProds = 0

   switch ( true ) {
      case windowWidth < 350:
         numRowProds = 2
      break;
      case windowWidth < 600:
         numRowProds = 2
      break;
      case windowWidth < 900:
         numRowProds = 5
      break;
      case windowWidth < 1200:
         numRowProds = 6
      break;
      case windowWidth < 1300:
         numRowProds = 7
      break;
      default:
         numRowProds = 7
   }

   let cardWidth = '150px'

   const fullCardWidth = ( Math.min( windowWidth, 1488 ) / numRowProds ) - ( 28 )
   cardWidth = fullCardWidth + ( ( fullCardWidth ) / numRowProds )

   const productCardArray = filteredProducts.map( product => {
      const vendorData = vendorList.find( vendor => vendor.slug === product.vendorSlug )

      return (
         <ProductCard
            product={ product }
            vendorSlug={ product.vendorSlug }
            handleModalClickOpen={ handleModalClickOpen }
            handleFriendsClickOpen={ handleFriendsClickOpen}
            vendorName={ product.vendorName }
            userData={ userData }
            handleFav={ handleFav }
            key={ product.id }
            
            vendorData={ vendorData }
            vendorLogo={ vendorLogo }
         />
      )
   } )

   // If productCardArray is empty, return a no matching products message
   if ( productCardArray.length === 0 ) {
      productCardArray.push(
         <Box sx={ { gridColumn: '1 / -1' } } key="noMatches">
            <Typography variant="h5" component="h1" sx={ { textAlign: 'center' } }>
               No matching products
            </Typography>
         </Box>
      )
   }

   /* If I ever want to add back in the vendorCard 
   if ( vendorCardSlug && vendorList.length ) {
      vendorData = vendorList.find( vendor => vendor.slug === vendorCardSlug ) 
      
      productCardArray.unshift(
         <VendorCard vendorData={ vendorList.find( vendor => vendor.slug === vendorCardSlug ) } />
      )  
   }
   */

   // So. Yeah. Kinda a hack until I figure out how to work with scrolling better.
   const scrollIntoViewWithOffset = ( selector, offset = 0 ) => {
      window.scrollTo( {
         behavior: 'smooth',
         top:
         document.querySelector( selector ).getBoundingClientRect().top -
         document.body.getBoundingClientRect().top -
         offset,
      } )
   }

   let topOffset = toolbarHeight
   let position = 'inherit'

   if ( scrollToTopButton ) {
      topOffset = '130px'
      position = 'sticky'
   }

   if ( channel ) {
      topOffset = '195px'
   }

   return (
      <Box
         id={ id }
         ref={ ref }
         sx={ {
            paddingBottom: '1rem',
            backgroundColor: "grey.main",
            scrollMarginTop: `${ scrollMarginTop + toolbarHeight }px`,
            // scrollMarginTop: channel ? '130px' : '65px',
         } }>
         <Container
            maxWidth="xl"
            sx={ {
               paddingLeft: { xs: 0, sm: '24px' },
               paddingRight: { xs: 0, sm: '24px' },
            } }
         >
            { scrollerData.headerTitle && (
               <>
               <Box
                  sx={ {
                     position: position,
                     top: topOffset,
                     zIndex: 2,
                     backgroundColor: headerBackgroundColor,
                     paddingLeft: '14px',
                     paddingRight: '14px',
                     height: '70px',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     borderTop: '21px solid #cbccc0',
                  } }
               >
                  <Box sx={{
                     flex: '1 1 100%',
                     minWidth: 0,
                  }}>
                     <Typography
                        component="h2"
                        variant="div"
                        sx={ {
                           marginBottom: '1rem',
                           marginTop: '1rem',
                           fontSize: {
                              'xs': '1rem',
                              'sm': '1.2rem'
                           },
                           fontWeight: 'bold',
                           textShadow: '0px 1px 1px #6666667a',
                           color: '#ffffff',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                        } }
                     >
                        { scrollerData.headerTitle }
                     </Typography>
                  </Box>

                  { scrollerData.scrollToTopButton && (
                     <Button
                        size="small"
                        aria-label="top"
                        color='white'
                        onClick={ () => {
                           // updte to useRef
                           scrollIntoViewWithOffset( '#topScrollTarget', channel ? 195 : 130 )
                        } }
                        variant="outlined" 
                        startIcon={ <ArrowUpwardIcon /> }
                     >
                        Top
                     </Button>
                  ) }
               </Box>
               </>
            ) }

            { vendorLogo && vendorCardSlug && vendorList && (
               <VendorCard vendorData={ vendorList.find( vendor => vendor.slug === vendorCardSlug ) } />
            ) }

            { /* Show vendor logo */}
            { scrollerData.headlineImage && (
               <Box sx={ {
                  display: 'grid',
                  justifyItems: 'start',
                  alignItems: 'center',
                  gridTemplateColumns: '1fr 1fr',
                  background: '#e3e4dd linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(227,228,221,1) 100%)',
               } }>
                  <Box
                     component="img"
                     src={ scrollerData.headlineImage }
                     alt={ scrollerData.headlineContent }
                     p={ 1 }
                     sx={ { maxHeight: '75px' } }
                  />
                  <Box sx={ { alignSelf: '' } }>
                     <Box sx={ { display: 'flex', alignItems: 'center' } }>
                        <PlaceIcon />
                        { scrollerData.headlineContent }
                     </Box>
                  </Box>
               </Box>
            )}
            
            <Box sx={ [ styles.scrollerWrapper ] } className="scrollerWrapper">
               <Box component="div" sx={
                  [ styles.scroller, styles.noScrollbar, { gridAutoColumns: `${ cardWidth }px` } ]
               } >
                  { productCardArray }
               </Box>
            </Box>
         </Container>
      </Box>
   )
} )
