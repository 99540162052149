import { useState, useEffect } from "react"
import { useSignup } from '../hooks/useSignup'
import { useTheme } from "@mui/material"

import { Box, Typography } from '@mui/material'
import { Container } from '@mui/system'
import Grid from '@mui/material/Unstable_Grid2'

import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'

import { NavLink as RouterLink } from "react-router-dom"

import MainContainer from '../components/Layout/MainContainer/MainContainer'

// import { useTheme } from "@mui/material/styles"

export default function Signup() {
   const [ showPassword, setShowPassword ] = useState(false);

   const handleClickShowPassword = () => setShowPassword((show) => !show)

   // const theme = useTheme()
   
   const styles = {
      // Not doing anything
      input: {
         "& .MuiFilledInput-root": {
            background: "#ffffff"
         }
      },
      actionBox: {
         backgroundColor: '#ffffff',
         padding: 3,
         borderRadius: '7px'
      },
      hempfrey: {
         maxWidth: '250px',
      },
      benefitListItem: {
         fontSize: '1.2rem',
         /* Can't get this to work????
         '&::before': {
            content: '"✓"'
         }
         */
      }
   }
   
   const [ name, setName ] = useState('')
   const [ email, setEmail ] = useState('')
   const [ password, setPassword ] = useState('')
   const [ inviteCode, setInviteCode ] = useState('')
   const { errorCode, signup, isPending } = useSignup()
   const [ formError, setFormError ] = useState( null )

   const renderErrorMessage = ( errorCode ) => {
      console.log('errorCode', errorCode)
      
      switch( errorCode ) {
         case 'auth/invalid-email':
            return 'Email address is invalid'
         case 'auth/weak-password':
            return 'Choose a more secure password'
         case 'auth/email-already-in-use':
            return 'Email already registered. Try to login or reset password.'
         case null:
            return ''
         default:
            return 'Something went wrong'
      }
   }

   useEffect( () => {
      setFormError( renderErrorMessage( errorCode ) )
   }, [ errorCode ] )

   const handleSubmit = ( e ) => {
      e.preventDefault()

      const passwordCheck = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

      if ( !email || !password || !name ) {
         setFormError('Please enter a valid name, email, and password.')
      } else if ( !password.match( passwordCheck ) ) {
         setFormError(`Choose a password between 7 to 15 characters containing at least one number and one special character.`)
      } else {
         signup( email, password, name, inviteCode )
      }
   }

   return (
      <MainContainer fullWidth={ true }>
         <Box sx={{
            position: 'sticky',
            top: { md: '65px', xs: '56px', sm: '64px'},
            zIndex: 3,
            backgroundColor: "#ffffff",
            borderBottom: '1px solid #ccc',
         }}
         >
            <Container
               maxWidth="xl"
               sx={ { display: 'flex', pt: 2, pb: 2, justifyContent: 'center', gap: 3 } }
            >
               <Typography
                  component="p"
                  variant="h5"
               >
                  Already Friends?
               </Typography>
               <Box>
                  <Button
                     variant="contained"
                     component={ RouterLink }
                     to="/login"
                  >
                     Log In
                  </Button>
               </Box>
            </Container>
         </Box>

         <Box pt={2} sx={ { backgroundColor: "grey.main", height: "100%", paddingTop: { xs: 2, md: 10 } } }>
            <Container maxWidth="md" sx={ { backgroundColor: '#e3e4dd' } }>
               <Box sx={ { padding: "48px 14px" } }>
                  <Grid container spacing={ 2 } sx={ { justifyContent: 'center', alignItems: 'center' } }>
                     <Grid sm={ 6 }>
                        <Box>
                           <Typography variant="h6" fontWeight="bolder" gutterBottom>
                              Get some serious perks by joining Hempstok:
                           </Typography>
                           <ul style={ { listStyleType: 'disc', marginLeft: '2rem' } }>
                              <li style={ styles.benefitListItem }>Access exclusive discounts</li>
                              <li style={ styles.benefitListItem }>Enter giveaways</li>
                              <li style={ styles.benefitListItem }>Save your favorite strains</li>
                              <li style={ styles.benefitListItem }>Get notified when strains drop (Coming soon!)</li>
                           </ul>
                        </Box>
                     </Grid>
                     <Grid sm={ 6 } >
                        <Typography component="h1" variant="h4" textAlign="center" mb={ 3 }>Let's Be Friends!</Typography>
                        <Box sx={ styles.actionBox }>
                           <form onSubmit={ handleSubmit }>
                              <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                 <InputLabel htmlFor="name">Name</InputLabel>
                                 <FilledInput
                                    id="name"
                                    onChange={ ( e ) => setName( e.target.value )}
                                    value={ name }
                                    autoComplete="name"
                                 />
                              </FormControl>

                              <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                 <InputLabel htmlFor="email">Email address</InputLabel>
                                 <FilledInput
                                    id="email"
                                    onChange={ ( e ) => setEmail( e.target.value )}
                                    value={ email }
                                    autoComplete="email"
                                 />
                              </FormControl>

                              <FormControl variant="filled" fullWidth={ true } sx={ { marginBottom: 2 } }>
                                 <InputLabel htmlFor="password">Password</InputLabel>
                                 <FilledInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={ ( e ) => setPassword( e.target.value )}
                                    value={ password }
                                    sx={ styles.input }
                                    autoComplete="new-password"
                                    endAdornment={
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={ handleClickShowPassword }
                                             edge="end"
                                          >
                                             { showPassword ? <VisibilityOff /> : <Visibility /> }
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                 />
                              </FormControl>
                              <Typography variant="body2" sx={ { marginBottom: 2 } }>
                                 7 to 15 characters, including a number and special character
                              </Typography>

                              <Box mt={ 3 } sx={ { textAlign: 'right' } }>
                                 {
                                    ( isPending )
                                    ?
                                       (
                                          <Button variant="contained" size="medium" disabled>
                                             Joining
                                          </Button>
                                       )
                                    :
                                       (
                                          <Button variant="contained" size="medium" type="submit">
                                             Join Hempstok!
                                          </Button>
                                       )
                                 }
                              </Box>
                              { formError && <Typography mt={ 2 } color="red.main">{ formError }</Typography> }
                           </form>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>
      </MainContainer>
   )
}
