import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { Box } from '@mui/system';
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import React from 'react';
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LogoutIcon from '@mui/icons-material/Logout'

function useQuery() {
   const { search } = useLocation()
 
   return React.useMemo(() => new URLSearchParams(search), [search]);
 }

export default function ExternalLink() {
   let query = useQuery()
   const navigate = useNavigate()
   
   return (
      <>
         <AppBar
            position="sticky"
            color="default"
            elevation={0}
            sx={ {
               borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            } }
         >
         <Container
            maxWidth="xl"
         >
            <Toolbar disableGutters>
               <IconButton aria-label="back" onClick={ () => navigate(-1)}>
                  <ArrowBackIcon />
               </IconButton>
               <RouterLink to={ "/" }>
                  <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                     <img
                        src="/hempstok-logo-300.png"
                        width="225px"
                        alt="Hempstok Logo"
                        style={{display: 'block'}}
                        onClick={ () => navigate(-1)}
                     />
                  </Box>
               </RouterLink>
               <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, flexGrow: 1 }}>
                  <img
                     src="/hempstok-logo-300.png"
                     width="225px"
                     alt="Hempstok Logo"
                     style={{display: 'block'}}
                  />
               </Box>
               <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { md: 'flex' } }}>
                  <Button
                     size="small"
                     aria-label="close"
                     color="primary"
                     href={ query.get("url") }
                     variant="outlined" endIcon={<LogoutIcon />}
                  >
                     Go to Site
                  </Button>
               </Box>
            </Toolbar>
         </Container>
         </AppBar>
         <iframe
            style={ {
               height: "100vh",
               border: "none",
               marginTop: "3em"
            } }
            title="External Link"
            width="100%"
            src={ query.get("url") }
         ></iframe>
      </>
   )
}