import { Box, Typography } from '@mui/material'
import { useTheme } from "@mui/material/styles"

export default function VendorLogoBox( { slug, inView, logoURL, iconURL, scrollToVendor, vendorName } ) {
   const theme = useTheme()

   const styles = {
      vendorLogoBox: {
         padding: 2,
         borderBottom: `1px solid #7baa45`,
         cursor: 'pointer',
         animation: 'fadeIn 1s',
         [theme.breakpoints.down('sm')]: {
            padding: 0
         },
      },
      activeVendor: {
         backgroundColor: '#e3e4dd'
      },
      logo: {
         [theme.breakpoints.down('xs')]: {
            display: 'none'
         },
      },
      icon: {
         [theme.breakpoints.up('xs')]: {
            display: 'none'
         },
      }
   }

   return (
      <Box
         className={ [ "vendorLogoBox", inView && 'activeVendor' ] }
         onClick={ () => scrollToVendor( slug ) }
         sx={ [ styles.vendorLogoBox, inView && styles.activeVendor ] }
      >
         <Box sx={ { display: { xs: 'none' , sm: 'block'} } }>
            <img src={ logoURL } width="100%" alt="" />
         </Box>
         <Box sx={ { display: { xs: 'block', sm: 'none' } } }>
            <Box sx={ { paddingTop: 2, paddingRight: 2, paddingLeft: 2 } }>
               <img src={ iconURL } width="100%" alt="" />
            </Box>
            <Box sx={ {
               fontSize: '.6rem',
               textOverflow: 'ellipsis',
               whiteSpace: 'nowrap',
               overflow: 'hidden',
               marginRight: '5px',
               marginLeft: '5px',
               textAlign: 'center'
            } }>
               { vendorName }
            </Box>
         </Box>
      </Box>
   )
}
