import { Box, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import { usePageContext } from "../../hooks/usePageContext"

const styles = {
   productContainer: {
      bgcolor: '#ffffff',
      height: "100%",
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
   }
}

const VendorLogoList = ( { vendorList, filteredProducts, products } ) => {

   const { scrollMarginTop, toolbarHeight } = usePageContext()
   
   return (
      vendorList.length > 0 && (
         <Box
            sx={ {
               backgroundColor: "grey.main",
               paddingTop: '2em',
               paddingBottom: '2em',
               scrollMarginTop: `${ scrollMarginTop + toolbarHeight }px`,
            } }
            id="topScrollTarget"
         >
            <Container maxWidth="xl">
               <Grid container spacing={ 2 } > {
                  vendorList.map( ( vendor, index ) => {
                     const offset_timestamp = (Date.now() / 1000) - (60 * 60 * 24 * 3)

                     const vendorProds = filteredProducts.filter( product => product.vendorSlug === vendor.slug )
                     const clickable = vendorProds.length > 0

                     let numUpdates = 0

                     vendorProds.map( product => {
                        if (
                           ( product.new_date && product.new_date.seconds > offset_timestamp) ||
                           ( product.restock_date && product.restock_date.seconds > offset_timestamp)
                        ) {
                              numUpdates++
                        }
                        return false
                     } )
                  
                     return (
                        <Grid key={ vendor.id } xs={ 4 } sm={ 3 } md={ 2 } >
                           <Paper
                              elevation={ 1 }
                              style={{
                                 ...styles.productContainer,
                                 opacity: clickable ? '1' : '0.4',
                                 cursor: clickable ? 'pointer' : 'not-allowed',
                                 borderTop: vendor.accountType === 'certified' ? '4px solid #e91e63' : '4px solid #ffffff',
                                 position: 'relative'
                              }}
                              // sx={ product.on_sale ? styles.onSaleBorder : {} }
                              onClick={ () => {
                                 if ( clickable ) {
                                    document.getElementById( vendor.slug ).scrollIntoView()
                                 } else {
                                    return false
                                 }
                              } }
                           >
                              <Box
                                 component={ 'img' }
                                 src={ vendor.logoURL }
                                 alt={ vendor.title }
                                 p={ 1 }
                                 sx={ {
                                    width: '100%',
                                 } }
                              />
                              { numUpdates > 0 && (
                                 <Box
                                    component={ 'div' }
                                    sx={{
                                       backgroundColor: 'pink.main',
                                       color: '#ffffff',
                                       display: 'inline',
                                       borderRadius: '50%',
                                       width: '25px',
                                       position: 'absolute',
                                       top: '7px',
                                       right: '7px',
                                       textAlign: 'center',
                                    }}
                                 >
                                    { numUpdates }
                                 </Box>
                              ) }
                           </Paper>
                        </Grid>
                     ) } )
                  }
               </Grid>
            </Container>
         </Box>
      )
   )
}

export default VendorLogoList
