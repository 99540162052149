import { useReducer, useEffect, useState } from "react"
import { db } from "../firebase/config"
import { collection, addDoc, setDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore'

let initialState = {
   document: null,
   isPending: false,
   error: null,
   success: null,
}

const firestoreReducer = (state, action) => {
   switch (action.type) {
      case 'IS_PENDING':
         return { isPending: true, document: null, success: false, error: null }
      case 'ADDED_DOCUMENT':
         return { isPending: false, document: action.payload, success: true, error: null }
      case 'DELETED_DOCUMENT':
         return { isPending: false, document: null, success: true, error: null }
      case 'ERROR':
         return { isPending: false, document: null, success: false, error: action.payload }
      case "UPDATED_DOCUMENT":
         return { isPending: false, document: action.payload, success: true,  error: null }
      default:
         return state
   }
}

export const useFirestore = ( c ) => {
   const [response, dispatch] = useReducer(firestoreReducer, initialState)
   const [isCancelled, setIsCancelled] = useState(false)

   // collection ref
   const ref = collection( db, c )

   // only dispatch if not cancelled
   const dispatchIfNotCancelled = (action) => {
      if (!isCancelled) {
         dispatch(action)
      }
   }
  
   // add a document
   const addDocument = async ( docObj, id = null ) => {
      dispatch({ type: 'IS_PENDING' })

      try {
         const createdAt = serverTimestamp()

         let addedDocument = null

         // Allows an id to be set
         if ( id ) {
         addedDocument = await setDoc( doc( db, c, id ), {
            ...docObj,
            createdAt
         } );
         } else {
         addedDocument = await addDoc( ref, {
            ...docObj,
            createdAt
         } )
         }

         dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument })
      }
      catch (err) {
         console.log( err )
         dispatchIfNotCancelled({ type: 'ERROR', payload: err.message })
      }
   }

   // delete a document
   const deleteDocument = async (id) => {
      dispatch({ type: 'IS_PENDING' })

      try {
         await ref.doc(id).delete()
         dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' })
      }
      catch (err) {
         dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not delete' })
      }
   }

   // update a document
   const updateDocument = async ( id, updates ) => {
      dispatch({ type: "IS_PENDING" })

      try {
         //const updatedDocument = await ref.doc(id).update(updates)
         const updatedDocument = await updateDoc( doc( db, c, id ), updates )
         dispatchIfNotCancelled( { type: "UPDATED_DOCUMENT", payload: updatedDocument } )
         return updatedDocument
      } 
      catch (error) {
         console.log('dis error', error)

         dispatchIfNotCancelled({ type: "ERROR", payload: error })
         return null
      }
   }

   useEffect(() => {
      return () => setIsCancelled(true)
   }, [])

   return { addDocument, deleteDocument, updateDocument, response }

}